import { Component, Input } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcbImageTextBlock } from 'src/rest-interfaces/IModularContent.field';


@Component( {
  selector: 'app-image-text-block',
  templateUrl: './image-text-block.component.html'
} )
export class ImageTextBlockComponent {
  @Input( 'content' ) content: IMcbImageTextBlock;
  public EColor = EColor;

  constructor() { }
}
