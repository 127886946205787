import { IFilterCategories, IFilterCategory } from 'src/rest-interfaces/ICategory';
import { IFilterCategoriesProjekte } from 'src/rest-interfaces/projekte/IFilterModelProjekte';

export abstract class ProjekteFilterHelper {
  /**
   * Selects category branches according to filterCategories.
   * Deselects all non-matching categories by default.
   *
   * @static
   * @param {IFilterCategories} categories
   * @param {IFilterCategoriesProjekte} filterCategories
   * @param {true} [deselect] When true, all matching categories are deselected, but does not deselect non-matching categories.
   * @returns {void}
   * @memberof ProjekteFilterHelper
   */
  public static selectAllCategoriesByFilter(
    categories: IFilterCategories,
    filterCategories: IFilterCategoriesProjekte,
    selectedCategoryTags: IFilterCategories,
    deselect?: true,
    depth?: number
  ): void {
    if ( typeof depth !== 'number' || depth < 0 ) {
      depth = 0;
    } else {
      ++depth;
    }

    if (
      !Array.isArray( categories )
      || typeof filterCategories !== 'object'
    ) { return; }

    for ( const c of categories ) {
      // deselect all categories by default,
      // except when deselect === true (deselecting matching categories)
      if ( deselect !== true ) {
        c.selected = false;
      }

      for ( const slug in filterCategories ) {
        if ( c.slug === slug ) {
          c.selected = !( deselect === true );

          // push category if it has no more children and it is deeper than 0
          if (
            depth > 0
            && (
              !c.hasOwnProperty( 'children' )
              || Object.keys( c.children ).length === 0
            )
          ) {
            if ( deselect !== true ) {
              selectedCategoryTags.push( c );
            }
          }

          ProjekteFilterHelper.selectAllCategoriesByFilter(
            c.children,
            filterCategories[ slug ],
            selectedCategoryTags,
            deselect,
            depth
          );
          break;
        }
      }
    }
  }

  /**
   * Deselects all categories in all levels.
   *
   * @static
   * @param {IFilterCategories} categories
   * @returns {void}
   * @memberof ProjekteFilterHelper
   */
  public static deselectAllCategories(
    categories: IFilterCategories,
  ): void {
    if ( !Array.isArray( categories ) ) { return; }

    for ( const c of categories ) {
      c.selected = false;
      ProjekteFilterHelper.deselectAllCategories( c.children );
    }
  }

  /**
   * Adds a category branch to filterCategories
   *
   * @static
   * @param {IFilterCategories} catsTopToBottom
   * @param {IFilterCategoriesProjekte} filterCategories
   * @memberof ProjekteFilterHelper
   */
  public static addCategoryBranch(
    catsTopToBottom: IFilterCategories,
    filterCategories: IFilterCategoriesProjekte
  ): void {
    let parentCatsObj: IFilterCategoriesProjekte = filterCategories;
    for ( const c of catsTopToBottom ) {
      if ( !parentCatsObj.hasOwnProperty( c.slug ) ) {
        c.selected = true;
        parentCatsObj[ c.slug ] = {};
      }
      parentCatsObj = parentCatsObj[ c.slug ];
    }
  }

  /**
   * Removes a category branch from filterCategories recursively
   *
   * @private
   * @static
   * @param {IFilterCategories} catsTopToBottom
   * @returns {boolean} Whether filterCategories is now empty (0 properies)
   * @memberof ProjekteFilterHelper
   */
  public static removeCategoryBranch(
    catsTopToBottom: IFilterCategories,
    filterCategories: IFilterCategoriesProjekte
  ): boolean {
    if (
      catsTopToBottom.length === 0
    ) {
      return typeof filterCategories !== 'object'
        || Object.keys( filterCategories ).length === 0;
    }

    // remove top most category
    const cat = catsTopToBottom.splice( 0, 1 )[ 0 ];
    cat.selected = false;
    if (
      filterCategories.hasOwnProperty( cat.slug )
    ) {
      if (
        ProjekteFilterHelper.removeCategoryBranch(
          catsTopToBottom,
          filterCategories[ cat.slug ]
        )
      ) {
        delete filterCategories[ cat.slug ];
        // console.log( cat.slug + ' removed', filterCategories );
        return Object.keys( filterCategories ).length === 0;
      }
      // console.log( cat.slug + ' not removed', filterCategories );
      return false;
    }
    return false;
  }

  public static findCategoryBranchArrFromChild(
    c: IFilterCategory,
    categories: IFilterCategories
  ): IFilterCategories {
    if ( typeof c !== 'object' || !Array.isArray( categories ) ) {
      return [];
    }

    for ( const searchCat of categories ) {
      if ( searchCat.slug === c.slug ) {
        return [ searchCat ];
      } else if (
        searchCat.hasOwnProperty( 'children' )
        && Array.isArray( searchCat.children )
      ) {
        const branch = this.findCategoryBranchArrFromChild(
          c,
          searchCat.children
        );
        if ( branch.length > 0 ) {
          branch.unshift( searchCat );
          return branch;
        }
      }
    }

    return [];
  }
}
