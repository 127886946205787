import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddThisComponent } from './addthis.component';
import { AddThisService } from './addthis.service';

@NgModule( {
  declarations: [
    AddThisComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    AddThisService
  ],
  exports: [
    AddThisComponent
  ]
} )
export class AddThisModule {
  constructor(
    addThisSvc: AddThisService
  ) { }
}
