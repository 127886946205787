import { Component, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { IModularContentJournal } from './../../../rest-interfaces/journal/IModularContentJournal.field';
import { TimelineMax } from 'gsap';
import { scrollMagicDuration, fullHookPos, mobileHookPos } from '../../_animations/animations.timings';

@Component({
  selector: 'app-modular-content-journal',
  templateUrl: './modular-content-journal.component.html'
})
export class ModularContentJournalComponent {
  @Input('modularContent') modularContent: IModularContentJournal;
  @ViewChild('scrollTrigger') trigger: ElementRef;

  public ready = false;
  public hookPos;
  public hookPosMobile = undefined;

  constructor() {}

  onScrollInit() {
    if (!this.ready) {
      this.onResize();
      this.ready = true;
    }
  }

  @HostListener('window:resize')
  onResize() {
    let hookMobile;
    if (window.innerWidth >= 993) {
      hookMobile = false;
      this.hookPos = fullHookPos;
    } else {
      hookMobile = true;
      this.hookPos = mobileHookPos;
    }
    if (this.hookPosMobile && !hookMobile || !this.hookPosMobile && hookMobile || this.hookPosMobile === undefined) {
      this.hookPosMobile = hookMobile;
      this.onScrollMagic();
    }
  }

  onScrollMagic() {
    const controller = new ScrollMagic.Controller();
    const blocks = this.trigger.nativeElement.querySelectorAll('.scrollBlock');
    for (const block of blocks) {
      const tween = new TimelineMax()
        .from(block, scrollMagicDuration, { opacity: 0, y: '+=20px', clearProps: 'y' }, 0);
      new ScrollMagic.Scene({
        triggerElement: block,
        triggerHook: this.hookPos,
      })
        .setTween(tween)
        .addTo(controller);
    }
  }
}
