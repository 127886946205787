import { Component, Input, HostBinding } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component( {
  selector: 'app-issuu',
  template: `<iframe allowfullscreen allow="fullscreen" [src]="safeEmbedUrl"></iframe>`,
  styles: [
    ':host { display: block; }',
    'iframe { width: 100%; height: 100%; border: none; }'
  ]
} )
export class IssuuComponent {
  @HostBinding( 'style.display' ) display = 'none';

  public safeEmbedUrl: SafeResourceUrl;
  @Input() public set embedUrl( val: string ) {
    if ( typeof val === 'string' ) {
      this.safeEmbedUrl = this.sanitizer.bypassSecurityTrustResourceUrl( val );
      this.display = 'block';
    } else if ( typeof val === 'object' && val ) {
      this.safeEmbedUrl = val;
      this.display = 'block';
    } else {
      console.warn( 'Invalid embedUrl in IssuuComponent' );
    }
  }

  constructor(
    private sanitizer: DomSanitizer
  ) { }
}
