import { Component } from '@angular/core';
import { EntryService } from 'src/app/services/entry.service';
import { IEntryUeberUns } from 'src/rest-interfaces/ueber-uns/IEntryUeberUns';
import { LocaleService } from '../../localization/locale.service';
import { ILocalizedComponent } from 'src/app/ILocalizedComponent';
import { Title } from '@angular/platform-browser';
import { generalAnimations } from 'src/app/_animations/general.animations';

@Component( {
  selector: 'app-ueber-uns',
  templateUrl: './ueber-uns.component.html',
  animations: [ generalAnimations ]
} )
export class UeberUnsComponent implements ILocalizedComponent {
  public content: IEntryUeberUns;

  constructor(
    private entrySvc: EntryService,
    private localeSvc: LocaleService,
    private title: Title
  ) {
    this.entrySvc.entry<IEntryUeberUns>( this.localeSvc.Locale === 'en' ? 'about' : 'ueber-uns' ).subscribe(
      t => {
        this.content = t;
        this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );
      },
      () => { this.localeSvc.navigateToHome(); }
    );
  }

  public getLocaleChangeUrl( targetLocale: string ): string {
    const locUrlProperty = 'url_' + targetLocale;
    if ( this.content && this.content.hasOwnProperty( locUrlProperty ) ) {
      return this.content[ locUrlProperty ];
    }
  }
}
