import { Routes } from '@angular/router';
import { HomeComponent } from '../page-components/home/home.component';
import { UeberUnsComponent } from '../page-components/ueber-uns/ueber-uns.component';
import { TeamComponent } from '../page-components/ueber-uns/team/team.component';
import { TeamMemberComponent } from '../page-components/ueber-uns/team/team-member/team-member.component';
import { DefaultPageComponent } from '../page-components/default-page/default-page.component';
import { AngeboteComponent } from '../page-components/angebote/angebote.component';
import { AngeboteItemComponent } from '../page-components/angebote/angebote-item.component';
import { ProjekteComponent } from '../page-components/projekte/projekte.component';
import { ProjekteItemComponent } from '../page-components/projekte/projekte-item.component';
import { GeneralSubsiteComponent } from '../page-components/general-subsite/general-subsite.component';
import { JournalComponent } from '../journal/journal.component';
import { JournalItemComponent } from '../journal/journal-item/journal-item.component';
import { KontaktComponent } from '../page-components/kontakt/kontakt.component';
import { LoginComponent } from '../page-components/login/login.component';
import { HdhComponent } from '../page-components/hdh/hdh.component';
import { SlideshowComponent } from '../page-components/slideshow/slideshow.component';
import { SucheComponent } from '../page-components/suche/suche.component';

export const ROUTES_CH: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },

  { path: 'ueber-uns', component: UeberUnsComponent, pathMatch: 'full' },
  { path: 'ueber-uns/team', component: TeamComponent, pathMatch: 'full' },
  { path: 'ueber-uns/team/:slug', component: TeamMemberComponent, pathMatch: 'full' },
  {
    path: 'ueber-uns',
    children: [
      { path: '**', component: DefaultPageComponent }
    ]
  },

  { path: 'hausdesholzes', component: HdhComponent, pathMatch: 'full' },

  { path: 'angebote', component: AngeboteComponent, pathMatch: 'full' },
  { path: 'angebote/:slug', component: AngeboteItemComponent, pathMatch: 'full' },

  { path: 'projekte', component: ProjekteComponent, pathMatch: 'full' },
  { path: 'projekte/:slug', component: ProjekteItemComponent, pathMatch: 'full' },

  {
    path: 'journal',
    children: [
      { path: '', component: JournalComponent, pathMatch: 'full' },
      { path: '**', component: JournalItemComponent, pathMatch: 'full' },
    ]
  },

  { path: 'kontakt', component: KontaktComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },

  { path: 'suche', component: SucheComponent, pathMatch: 'full' },

  { path: 'agb', component: DefaultPageComponent, pathMatch: 'full' },
  { path: 'datenschutz', component: DefaultPageComponent, pathMatch: 'full' },
  { path: 'impressum', component: DefaultPageComponent, pathMatch: 'full' },
  { path: 'slideshow/:slug', component: SlideshowComponent, pathMatch: 'full' },
  // Section "Allgemeine Unterseiten"
  { path: '**', component: GeneralSubsiteComponent, pathMatch: 'full' }
];
