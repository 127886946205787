import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from '../api/api.service';
import { BreadcrumbsService } from './breadcrumbs.service';
import { Observable } from 'rxjs';
import { IEntryJournal, IEntryJournalGrid } from 'src/rest-interfaces/IEntryJournal';
import { IEntryMedien } from 'src/rest-interfaces/IEntryMedien';
import { filter, map, tap, zip } from 'rxjs/operators';
import { IJournalCategory } from 'src/rest-interfaces/journal/IJournalCategory';
import { IJournalOverview } from 'src/rest-interfaces/journal/IJournalOverview';


@Injectable()
export class MedienService {
  constructor(
    private API: ApiService,
    private router: Router,
    private breadcrumbsSvc: BreadcrumbsService
  ) { }

  public categories(): Observable<Array<IJournalCategory>> {
    return this.router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).pipe(
      zip( this.API.get<Array<IJournalCategory>>( '/medien/categories' ) )
    ).pipe(
      map( d => d[ 1 ] )
    );
  }

  public feed( pagenum?: number, categoryId?: number, term?: string ): Observable<Array<IEntryMedien>> {
    if (
      typeof pagenum !== 'number'
      || pagenum <= 0
    ) {
      pagenum = 1;
    }
    if (
      typeof categoryId !== 'number'
      || categoryId < 0
    ) {
      categoryId = 0;
    }

    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '1',
        title: 'Medien',
        uri: 'medien'
      }
    );
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '2',
        title: 'Medien',
        uri: 'de/medien'
      }
    );
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '3',
        title: 'Medien',
        uri: 'en/medien'
      }
    );

    return this.API.get<Array<IEntryMedien>>(
      '/medien/feed/'
      + ( typeof categoryId === 'number' ? 'cid' + categoryId + '/' : '' )
      + pagenum
      + ( typeof term === 'string' ? '?term=' + encodeURIComponent( term ) : '' )
    );
  }

  public projektFeed( pagenum?: number, projectId?: string, term?: string ): Observable<Array<IEntryMedien>> {
    
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '1',
        title: 'Medien',
        uri: 'medien'
      }
    );
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '2',
        title: 'Medien',
        uri: 'de/medien'
      }
    );
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '3',
        title: 'Medien',
        uri: 'en/medien'
      }
    );

    return this.API.get<Array<IEntryMedien>>(
      '/medien/feed/' + 'projekt' + projectId + '/' + pagenum
    );
  }

  public getJournalMedienTitel(): Observable<IJournalOverview> {
    return this.API.get<IJournalOverview>('/structure/journal');
  }

  public uri( uri: string ): Observable<IEntryMedien> {
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '1',
        title: 'Medien',
        uri: 'medien'
      }
    );
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '2',
        title: 'Medien',
        uri: 'de/medien'
      }
    );
    this.breadcrumbsSvc.ApiTapper(
      {
        siteId: '3',
        title: 'Medien',
        uri: 'en/medien'
      }
    );

    return this.router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).pipe(
      zip( this.API.get<IEntryMedien>( uri ) )
    ).pipe(
      map( d => d[ 1 ] )
    ).pipe(
      tap( this.breadcrumbsSvc.ApiTapper.bind( this.breadcrumbsSvc ) )
    ).pipe(
      tap( () => { window.scroll( 0, 0 ); } )
    );
  }

  // --------------------------------------------
  // Helpers

  public getStoryCategoriesStr( story: IEntryJournalGrid ): string {
    return Object.keys( story.kategorie ).map(
      cat => story.kategorie[cat].title
    ).join( ', ' );
  }
}
