import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcb3SPaltenTeasersTabelle, IMcbEintraegeTable } from 'src/rest-interfaces/IModularContent.field';
import { UrlHelper } from 'src/app/helpers/url.helper';

type IMcb3SPaltenTeasersTabelleWithLocalUrlCheck = IMcb3SPaltenTeasersTabelle
  & { eintraege: Array<IMcbEintraegeTable & { isLocalUrl: boolean; }>; };

@Component( {
  selector: 'app-three-col-text-teaser',
  templateUrl: './three-col-text-teaser.component.html'
} )
export class ThreeColTextTeaserComponent implements OnChanges {
  @Input( 'content' ) content: IMcb3SPaltenTeasersTabelleWithLocalUrlCheck;
  public EColor = EColor;

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'content':
          const content = changes.content.currentValue as IMcb3SPaltenTeasersTabelleWithLocalUrlCheck;
          for ( const e of content.eintraege ) {
            e.isLocalUrl = UrlHelper.isLocalUrl( e.uri );
          }
          this.content = content;
      }
      break;
    }
  }
}
