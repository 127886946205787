import { Component, Input } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcbKontakteBanner } from 'src/rest-interfaces/IModularContent.field';

@Component( {
  selector: 'app-contact-banner',
  templateUrl: './contact-banner.component.html'
} )
export class ContactBannerComponent {
  @Input( 'content' ) content: IMcbKontakteBanner;
  public EColor = EColor;
}
