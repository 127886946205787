import { Directive, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { LocaleService } from '../localization/locale.service';
import { NavigationService } from '../services/navigation.service';
import { FooterService } from '../services/footer.service';
import { ELocale } from '../localization/ELocale';

@Directive( {
  selector: '[setLocale]'
} )
export class SetLocaleDirective implements OnChanges {
  @Input() setLocale: ELocale;

  @HostListener( 'click' ) onclick() {
    // console.log( 'setLanguage click' );
    this.LocaleSvc.setLocale( this.setLocale );
  }

  constructor(
    private LocaleSvc: LocaleService,
    private navigationSvc: NavigationService,
    private footerSvc: FooterService
  ) { }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'setLocale':
          this.setLocale = changes.setLocale.currentValue;
          break;
      }
    }
  }
}
