import { Component, Input } from '@angular/core';
import { LocaleService } from 'src/app/localization/locale.service';
import { NewsfeedBlock } from 'src/rest-interfaces/IEntryHome';

@Component( {
  selector: 'app-sidebar-news',
  templateUrl: 'sidebar-news.component.html'
} )
export class SidebarNewsPreview {
  @Input() public content: Array<NewsfeedBlock>;

  constructor(
    public localeSvc: LocaleService
  ) { }
}
