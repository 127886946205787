import { Component, Input } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcbFreitextBlock } from 'src/rest-interfaces/IModularContent.field';


@Component( {
  selector: 'app-freitext-block',
  templateUrl: './freitext-block.component.html'
} )
export class FreitextBlockComponent {
  @Input( 'content' ) content: IMcbFreitextBlock;
  public EColor = EColor;

  constructor() { }
}
