import { Component } from '@angular/core';
import { EntryService } from 'src/app/services/entry.service';
import { LocaleService } from '../../localization/locale.service';
import { IEntrySlideshow } from 'src/rest-interfaces/IEntrySlideshow';
import { ILocalizedComponent } from 'src/app/ILocalizedComponent';
import { Title } from '@angular/platform-browser';
import { generalAnimations } from 'src/app/_animations/general.animations';
import { SliderConfigService } from 'src/app/services/slider-config.service';

@Component( {
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  animations: [ generalAnimations ]
} )
export class SlideshowComponent implements ILocalizedComponent {
  public content: IEntrySlideshow;

  constructor(
    private entrySvc: EntryService,
    private localeSvc: LocaleService,
    private title: Title,
    public SliderConfigSvc: SliderConfigService,
  ) {
    this.entrySvc.customControllerUri<IEntrySlideshow>(
      this.localeSvc.RouterUri
    ).subscribe(
      t => {
        this.content = t;
        this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );
      },
      () => { this.localeSvc.navigateToHome(); }
    );
  }

  public getLocaleChangeUrl( targetLocale: string ): string {
    const locUrlProperty = 'url_' + targetLocale;
    if ( this.content && this.content.hasOwnProperty( locUrlProperty ) ) {
      return this.content[ locUrlProperty ];
    }
  }
}
