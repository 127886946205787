import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Breadcrumb } from '../breadcrumbs/Breadcrumb';
import { filter } from 'rxjs/operators';
import { LocaleService } from '../localization/locale.service';
import { UrlHelper } from '../helpers/url.helper';
import { ELocale } from '../localization/ELocale';
import { Subject } from 'rxjs';

@Injectable()
export class LightboxService {
  public lightboxContent: any;
  public showLightbox: boolean

  private customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();

  public currentSlide = 0;

  constructor() {
    this.showLightbox = false;
  }

  public setLightboxContent(content: any, initialSlide?: number) {

    if(!content.bild) {
      content.bild = content.projektBild || content.hauptbild;
    }

    this.lightboxContent = content;

    if(initialSlide !== undefined) {
      this.currentSlide = initialSlide;
      this.lightboxContent.sliderConfig = {
        "initialSlide": initialSlide
      }
    }

    this.showLightbox = true;
    this.callComponentMethod();
  }

  public hideLightbox() {
    this.showLightbox = false;
  }

  callComponentMethod() {
    this.customSubject.next();
  }

  // private focusLightbox: () => void;

  // bindFunction(fn: () => void) {
  //   this.focusLightbox = fn;
  // }
}
