import { Component } from '@angular/core';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { Breadcrumb } from './Breadcrumb';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component( {
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: [
    ':host { display: block; }'
  ]
} )
export class BreadcrumbsComponent {
  public bc: Array<Breadcrumb>;

  constructor(
    router: Router,
    breadcrumbsSvc: BreadcrumbsService
  ) {
    this.bc = breadcrumbsSvc.Breadcrumbs;
    router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).subscribe( () => { this.bc = breadcrumbsSvc.Breadcrumbs; } );
  }
}
