import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { generalAnimations } from 'src/app/_animations/general.animations';
import {NgForm} from '@angular/forms';
import { ApiService } from 'src/app/api/api.service';
import { LoginService } from 'src/app/services/login.service';



@Component( {
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [ generalAnimations ]
} )
export class LoginComponent {

  constructor(
    public loginSvc: LoginService,
    private router: Router,
  ) {
  }


  onSubmit(f: NgForm) {
    console.log(f.value);  // { first: '', last: '' }
    console.log(f.valid);  // false

    this.loginSvc.login(f.value);
  }

  logout() {
    this.loginSvc.logout();
  }

}
