import { trigger, animate, transition, group, query, style } from '@angular/animations';

export const homeAnimations =
  trigger( 'homeAnimations', [
    transition( ':enter', [
      // initial states
      query( '.home-intro', style( { opacity: 0 } ), { optional: false } ),
      query( '.cell-text', style( { opacity: 0, transform: 'translateY(-20px)' } ), { optional: true } ),
      query( '.cell-content-preview', style( { opacity: 0, transform: 'translateY(-20px)' } ), { optional: true } ),
      group( [
        // enter states
        query( '.home-intro', [
          animate( '500ms 1000ms ease-out', style( { opacity: 1 } ) )
        ], { optional: false } ),
        query( '.cell-text', [
          animate( '500ms 1700ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
        ], { optional: false } ),
        query( '.cell-content-preview', [
          animate( '500ms 2200ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
        ], { optional: false } ),
      ] )
    ] ),
  ] );
