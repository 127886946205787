import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocaleService } from '../localization/locale.service';

declare function hyphenator( value: string, locale?: string ): string;

@Injectable()
export class HyphenatorService {
  private worker: Worker & {
    postMessage( message: {
      id: string,
      text: string;
      locale: string;
    } ): void;
  };

  private workerQueue: { [ id: string ]: any } = {};

  constructor(
    private localeSvc: LocaleService
  ) {
    // console.log( 'initializing HyphenatorService' );
    if ( ( window as any ).Worker ) {
      // Initialize webworker
      this.worker = new Worker( 'assets/hyphen.js' );
      this.worker.onmessage = this.workerSuccessResponseHandler.bind( this );
      this.worker.onerror = this.workerSuccessResponseHandler.bind( this );
    } else {
      // Fallback: Import as script
      const hyphenElement = document.createElement( 'script' );
      hyphenElement.setAttribute( 'type', 'text/javascript' );
      hyphenElement.setAttribute( 'src', 'assets/hyphen.js' );
      hyphenElement.setAttribute( 'defer', 'true' );
      document.getElementsByTagName( 'body' )[ 0 ].appendChild( hyphenElement );
    }
  }

  public hyphenate( text: string, locale?: string ): Observable<string> {
    if ( typeof locale !== 'string' ) {
      locale = this.localeSvc.Locale;
    }
    if ( this.worker ) {
      // Use webworker
      return new Observable(
        observer => {
          const id = Math.random().toString( 16 ).substr( 2 );
          this.workerQueue[ id ] = observer;

          this.worker.postMessage(
            {
              id: id,
              text: text,
              locale: locale
            }
          );
        }
      );
    } else {
      // Use fallback
      return of( hyphenator( text, locale ) );
    }
  }

  private workerSuccessResponseHandler( e: { data: { id: string; text: string; }; } ) {
    if (
      e && e.data && typeof e.data.id === 'string' && e.data.id.length
      && this.workerQueue && this.workerQueue.hasOwnProperty( e.data.id )
    ) {
      this.workerQueue[ e.data.id ].next( e.data.text );
      delete this.workerQueue[ e.data.id ];
    } else {
      this.workerErrorResponseHandler( e );
    }
  }
  private workerErrorResponseHandler( e: any ) {
    console.error( 'workerErrorResponseHandler', e );
  }
}
