import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AddThisService } from 'src/app/addthis/addthis.service';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcbWebcam } from 'src/rest-interfaces/IModularContent.field';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html'
})
export class WebcamComponent {
  @Input('content') content: IMcbWebcam;
  public EColor = EColor;
  public url: SafeResourceUrl;
  constructor(
    private sanitizer: DomSanitizer
  ){}

  public getUrl() {
    if (!this.url) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.webcamUrl);
    }
    return this.url
  }
}
