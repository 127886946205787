import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../localization/locale.service';
import { HyphenatorService } from './hyphenator.service';
import { Observable, of } from 'rxjs';

@Pipe( {
  name: 'hyphenate'
} )
export class HyphenatePipe implements PipeTransform {
  constructor(
    private localeSvc: LocaleService,
    private hyphen: HyphenatorService
  ) { }

  transform( value: string, locale?: string ): Observable<string> {
    if ( typeof locale !== 'string' ) {
      locale = this.localeSvc.Locale;
    }
    if (
      typeof value !== 'string'
      || !value.length
    ) {
      return of( '' );
    }
    return this.hyphen.hyphenate( value, locale );
  }
}
