import { trigger, animate, transition, group, query, style } from '@angular/animations';

export const navAnimations =
  trigger( 'navAnimations', [
    transition( 'hidden => show', [
      query( '.nav', style( { transform: 'translateY(-100vh)', pointerEvents: 'none' } ), { optional: false } ),
      query( '.language-switcher', style( { opacity: 0, transform: 'translateY(-15px)' } ), { optional: false } ),
      query( '.navigation', style( { opacity: 0, transform: 'translateY(-15px)' } ), { optional: false } ),
      group( [
        query( '.nav', [
          animate( '400ms 400ms ease-out', style( { transform: 'translateY(0)' } ) )
        ], { optional: true } ),
        query( '.language-switcher', [
          animate( '400ms 1200ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
        ], { optional: true } ),
        query( '.navigation', [
          animate( '400ms 800ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
        ], { optional: true } ),
      ] )
    ] ),
    transition( 'show => hidden', [
      query( '.nav', style( { transform: 'translateY(0)', visibility: 'visible', pointerEvents: 'none' } ), { optional: false } ),
      query( '.language-switcher', style( { opacity: 1, visibility: 'visible' } ), { optional: false } ),
      query( '.navigation', style( { opacity: 1, visibility: 'visible' } ), { optional: false } ),
      group( [
        query( '.nav', [
          animate( '400ms 800ms ease-out', style( { transform: 'translateY(-100vh)' } ) )
        ], { optional: true } ),
        query( '.language-switcher', [
          animate( '400ms 400ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
        query( '.navigation', [
          animate( '400ms 0ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
      ] )
    ] ),
  ] );
