import { Component } from '@angular/core';
import { generalAnimations } from '../../_animations/general.animations';
import { Meta, Title } from '@angular/platform-browser';
import { ScrollService } from '../../services/scroll.service';
import { MedienService } from '../../services/medien.service';
import { IJournalCategory } from 'src/rest-interfaces/journal/IJournalCategory';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '../../localization/locale.service';
import { EntryService } from '../../services/entry.service';
import { IEntryMedien } from 'src/rest-interfaces/IEntryMedien';

@Component( {
  selector: 'app-medien',
  templateUrl: './medien.component.html',
  animations: [ generalAnimations ]
} )
export class MedienComponent {
  public stories: Array<IEntryMedien>;
  public categories: Array<IJournalCategory> = [];
  public hasMoreStories = false;
  public isLoadingMore = false;

  private currentStoriesPage = 0;
  public selectedCategory: IJournalCategory;
  public searchterm = '';
  public term: string;
  public title: string;

  constructor(
    private entrySvc: EntryService,
    public medienSvc: MedienService,
    private localeSvc: LocaleService,
    private scrollService: ScrollService,
    private route: ActivatedRoute,
  ) {
    this.route.url.subscribe(
      () => {
        this.medienSvc.getJournalMedienTitel().subscribe(
          jorunalPage => {
            this.title = jorunalPage.medienTitel;
          },
          error => console.error( 'Error loading medien title: ', error )
        )
      }
    );
    this.medienSvc.categories().subscribe(
      categories => { this.categories = categories; }
    );
    this.getStories( null, '' );
  }

  public getStories( category?: IJournalCategory, term?: string ): void {
    if (
      typeof category === 'object'
      && category !== this.selectedCategory
      || typeof term === 'string'
      && term !== this.term
    ) {
      // remove all previously loaded stories
      // because we are getting new ones by category or term
      this.stories = [];
      this.currentStoriesPage = 0;
      this.hasMoreStories = false;

      if (
        typeof category === 'object'
        && category !== this.selectedCategory
      ) {
        this.selectedCategory = category;
      }

      if (
        typeof term === 'string'
        && term !== this.term
      ) {
        this.term = term;
      }

      // console.log( 'loading stories', this.selectedCategory, 'term="' + this.term + '"' );
      this.loadMoreStories();
    }
  }

  public loadMoreStories(): void {
    if ( this.isLoadingMore ) { return; }

    ++this.currentStoriesPage;

    this.isLoadingMore = true;
    this.medienSvc.feed(
      this.currentStoriesPage,
      typeof this.selectedCategory === 'object'
        && this.selectedCategory !== null
        && this.selectedCategory.hasOwnProperty( 'id' )
        ?
        Number.parseInt( this.selectedCategory.id, 10 )
        :
        0,
      this.term
    ).subscribe(
      entries => {
        this.hasMoreStories = this.currentStoriesPage === 1
          && entries.length > 8
          ?
          true
          :
          entries.length > 8;

        this.stories = this.stories.concat(
          entries.slice( 0, 8 )
        );

        this.isLoadingMore = false;
      },
      () => {
        this.isLoadingMore = false;
      }
    );
  }

  public onCatSelected( c?: IJournalCategory ) {
    if ( typeof c !== 'object' ) {
      c = null;
    }
    this.getStories( c );
  }

  public onTermChanged( e: Event ): void {
    e.preventDefault();
    this.getStories( this.selectedCategory, this.searchterm );
  }

  public onScrollDown( event: Event ) {
    this.scrollService.triggerScrollToOffset( 'scrollDestination', -100 );
  }

}
