import { trigger, animate, transition, group, query, style } from '@angular/animations';

export const headerAnimations =
  trigger( 'headerAnimations', [
    transition( '* => navigationVisible', [
      query( '.nav', style( { transform: 'translateY(-100vh)' } ), { optional: false } ),
      query( '.language-switcher', style( { opacity: 0, transform: 'translateY(-15px)' } ), { optional: false } ),
      query( '.navigation', style( { opacity: 0, transform: 'translateY(-15px)' } ), { optional: false } ),
      query( '.menu-label', style( { visibility: 'visible' } ), { optional: false } ),
      query( '.header-logo', style( { visibility: 'visible' } ), { optional: false } ),
      group( [
        query( '.nav', [
          animate( '400ms 400ms ease-out', style( { transform: 'translateY(0)' } ) )
        ], { optional: true } ),
        query( '.language-switcher', [
          animate( '400ms 1200ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
        ], { optional: true } ),
        query( '.navigation', [
          animate( '400ms 800ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
        ], { optional: true } ),
        query( '.menu-label', [
          animate( '400ms 0ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
        query( '.header-logo', [
          animate( '400ms 0ms ease-out', style( { opacity: 0, visibility: 'hidden' } ) )
        ], { optional: true } ),
        query( '.logo-full', [
          animate( '400ms 0ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
        query( '.logo-full__home', [
          animate( '400ms 0ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
        query( '.logo-full__journal', [
          animate( '400ms 0ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
        query( '.logo-small', [
          animate( '400ms 0ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
      ] )
    ] ),
    transition( 'navigationVisible => *', [
      query( '.nav', style( { transform: 'translateY(0)', visibility: 'visible' } ), { optional: false } ),
      query( '.language-switcher', style( { opacity: 1, visibility: 'visible' } ), { optional: false } ),
      query( '.navigation', style( { opacity: 1, visibility: 'visible' } ), { optional: false } ),
      query( '.menu-label', style( { opacity: 0 } ), { optional: false } ),
      query( '.header-logo', style( { opacity: 0 } ), { optional: false } ),
      group( [
        query( '.nav', [
          animate( '400ms 800ms ease-out', style( { transform: 'translateY(-100vh)' } ) )
        ], { optional: true } ),
        query( '.language-switcher', [
          animate( '400ms 400ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
        query( '.navigation', [
          animate( '400ms 0ms ease-out', style( { opacity: 0 } ) )
        ], { optional: true } ),
        query( '.menu-label', [
          animate( '400ms 1200ms ease-out', style( { opacity: 1 } ) )
        ], { optional: true } ),
        query( '.header-logo', [
          animate( '400ms 1200ms ease-out', style( { opacity: 1 } ) )
        ], { optional: true } ),
      ] )
    ] ),
    transition( ':enter', [
      query( 'header', style( { opacity: 0, transform: 'translateY(-20px)' } ), { optional: false } ),
      group( [
        query( 'header', [
          animate( '500ms 0ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
        ], { optional: true } ),
      ] )
    ] )
  ] );
