import { EContentBlockOrientation } from 'src/rest-interfaces/EContentBlockOrientation';

export class ModularContentHelper {
  public static readonly COL_CLASSES_LEFT = 'off-s-2 col-s-20 off-m-2 col-m-20 off-l-2 col-l-14 off-xl-2 col-xl-14';
  public static readonly COL_IMAGE_CLASSES_LEFT = 'off-s-0 col-s-24 off-m-0 col-m-24 off-l-2 col-l-14 off-xl-2 col-xl-14';
  public static readonly COL_CLAIM_CLASSES_LEFT = 'off-s-2 col-s-20 off-l-2 col-l-14 off-xl-2 col-xl-14';
  public static readonly COL_AUTHOR_CLASSES_LEFT = 'off-s-2 col-s-20 off-l-2 col-l-8 off-xl-2 col-xl-8';

  public static BlockOrientationToColClasses( blockOrientation: EContentBlockOrientation ): string {
    switch ( blockOrientation ) {
      case EContentBlockOrientation.Mitte:
        return 'off-s-2 col-s-20 off-m-2 col-m-20 off-l-5 col-l-14 off-xl-5 col-xl-14';
      case EContentBlockOrientation.Rechts:
        return 'off-s-2 col-s-20 off-m-2 col-m-20 off-l-8 col-l-14 off-xl-8 col-xl-14';
      default: // Links
        return ModularContentHelper.COL_CLASSES_LEFT;
    }
  }

  public static ImageBlockOrientationToColClasses( blockOrientation: EContentBlockOrientation ): string {
    switch ( blockOrientation ) {
      case EContentBlockOrientation.Mitte:
        return 'off-s-0 col-s-24 off-m-0 col-m-24 off-l-5 col-l-14 off-xl-5 col-xl-14';
      case EContentBlockOrientation.Rechts:
        return 'off-s-0 col-s-24 off-m-0 col-m-24 off-l-8 col-l-14 off-xl-8 col-xl-14';
      default: // Links
        return ModularContentHelper.COL_IMAGE_CLASSES_LEFT;
    }
  }

  public static ClaimBlockOrientationToColClasses( blockOrientation: EContentBlockOrientation ): string {
    switch ( blockOrientation ) {
      case EContentBlockOrientation.Mitte:
        return 'off-s-2 col-s-20 off-l-5 col-l-14 off-xl-5 col-xl-14 block-center';
      case EContentBlockOrientation.Rechts:
        return 'off-s-2 col-s-20 off-l-8 col-l-14 off-xl-8 col-xl-14 block-right';
      default: // Links
        return ModularContentHelper.COL_CLAIM_CLASSES_LEFT;
    }
  }

  public static AuthorOrientationToColClasses( blockOrientation: EContentBlockOrientation ): string {
    switch ( blockOrientation ) {
      case EContentBlockOrientation.Mitte:
        return 'off-s-2 col-s-20 off-l-8 col-l-8 off-xl-8 col-xl-8';
      case EContentBlockOrientation.Rechts:
        return 'off-s-2 col-s-20 off-l-14 col-l-8 off-xl-14 col-xl-8';
      default: // Links
        return ModularContentHelper.COL_AUTHOR_CLASSES_LEFT;
    }
  }
}
