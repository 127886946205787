import { Component, Input } from '@angular/core';
import { IMcbJournalHdhPreview } from 'src/rest-interfaces/IModularContent.field';
import { JournalService } from 'src/app/services/journal.service';

@Component( {
  selector: 'app-journal-hdh-preview',
  templateUrl: './journal-hdh-preview.component.html'
} )
export class JournalHdhPreviewComponent {
  @Input() content: IMcbJournalHdhPreview;

  constructor( public journalSvc: JournalService ) { }
}
