import { ELocale } from '../localization/ELocale';
import { environment } from 'src/environments/environment';

export class UrlHelper {
  /**
   * Get path from <base href="..."/>.
   * The domain part is always removed.
   *
   * @static
   * @returns {string} Path starts and ends with '/'. E.g.: /pirminjung/web/
   * @memberof UrlHelper
   */
  public static getBasePathAbsolute(): string {
    const base = document.getElementsByTagName( 'base' )[ 0 ] as HTMLBaseElement;
    let href = base.href;

    if ( href.length > 0 ) {
      if ( href.substr( 0, 4 ) === 'http' ) {
        href = href.replace( /^https?:\/\//, '' );

        const slashIndex = href.indexOf( '/' );
        if ( slashIndex >= 0 ) {
          return href.substr( slashIndex );
        } else {
          return '/';
        }
      } else {
        if ( href.charAt( 0 ) !== '/' ) {
          return '/' + href;
        }
        return href;
      }
    }
    return '/';
  }

  public static getMatchingSegmentsCount( a: Array<string>, b: Array<string> ): number {
    let matchingSemgents = 0;
    for (
      matchingSemgents = 0;
      matchingSemgents < a.length
      && matchingSemgents < b.length
      && a[ matchingSemgents ] === b[ matchingSemgents ];
      ++matchingSemgents
    ) { }
    return matchingSemgents;
  }

  public static isLocalUrl( url: string ): boolean {
    return url.substr( 0, 4 ) !== 'http'
      && ( url.charAt( 0 ) !== '/' || url.substr( 1, 5 ) !== 'files' )
      && url.substr( 0, 5 ) !== 'files';
  }

  public static currentLocaleFromDomain(): ELocale {
    const hostname = location.hostname;
    // console.log( 'currentLocaleFromDomain()', hostname );
    switch ( hostname ) {
      case environment.domains.de.hostname:
        return ELocale.de;
      case environment.domains.en.hostname:
        return ELocale.en;
      case environment.domains.ch.hostname:
      default:
        return ELocale.ch;
    }
  }

  public static LocaleHomeUrl( locale: ELocale ): string {
    switch ( locale ) {
      case ELocale.de:
        return environment.domains.de.homeUrl;
      case ELocale.en:
        return environment.domains.en.homeUrl;
      case ELocale.ch:
      default:
        return environment.domains.ch.homeUrl;
    }
  }
}
