import { Component, OnInit, HostBinding, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';
import { SocialMediaService } from 'src/app/services/social-media.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FooterService } from '../../services/footer.service';

interface ILinkedInPost {
  id: string;
  safeIFrameUrl: SafeResourceUrl;
}

@Component( {
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: [ './social-media.component.scss' ]
} )
export class SocialMediaComponent implements OnDestroy {
  public facebookPostIds: string[];
  // public instagramPostHtml: SafeHtml;
  public instagramPosts: string[];
  public linkedinPosts: ILinkedInPost[];
  public htmlLinkedinContent: string;

  public title: string;
  public subtitle: string;

  @HostBinding( 'class.block' ) classBlock = true;
  @HostBinding( 'class.bg-gray' ) classGray = true;

  constructor(
    private fb: FacebookService,
    private socialMediaService: SocialMediaService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    public footerSvc: FooterService,
  ) {
    const initParams: InitParams = {
      appId: 'pirminjung',
      xfbml: true,
      version: 'v3.2'
    };
    socialMediaService.getFacebookPostIds().subscribe(
      value => {
        this.facebookPostIds = value;
        // The facebook plugin needs to be initialized after loading the post ids
        fb.init( initParams );
      },
      err => console.error( 'Can\'t find facebook post ids: ', err )
    );

    this.getLinkedinIds();
    this.getInstagramHTML();
  }

  ngOnDestroy() {
    // Remove the  instagram script from window. Otherwise it won't be run again when coming back to the page.
    (window as any).instgrm = null;
  }

  public getInstagramHTML(): void {
    this.socialMediaService.getInstagramEmbedHtml().subscribe(
      posts => {
        if ( Array.isArray( posts ) ) {
          for ( let i = 0; i < posts.length; ++i ) {
            posts[ i ] = this.sanitizer.bypassSecurityTrustHtml( posts[ i ] ) as string;
          }
        }
        this.instagramPosts = posts;
        // Attach the instagram script to the document. This has to happen after adding the posts to the document.
        this.attachInstaScript();
      },
      err => console.error( 'Error loading instagram posts: ', err )
    );
  }

  private attachInstaScript() {
    // const scriptEl = document.createElement( 'script' );
    // scriptEl.setAttribute('id', 'instagram');
    // scriptEl.setAttribute( 'src', '//www.instagram.com/embed.js');
    // this.el.nativeElement.appendChild( scriptEl );

    setTimeout(() => {
      (window as any).instgrm.Embeds.process();
    }, 500);
  }


  public getLinkedinIds(): void {
    this.socialMediaService.getLinkedinPostIds().subscribe(
      jorunalPage => {
        this.title = jorunalPage.socialMediaTitle;
        this.subtitle = jorunalPage.socialMediaSubtitle;
        this.linkedinPosts = jorunalPage.linkedinIds.map(
          ( linkedinId ): ILinkedInPost => (
            {
              id: linkedinId.postid,
              safeIFrameUrl: this.sanitizer.bypassSecurityTrustResourceUrl(
                'https://www.linkedin.com/embed/feed/update/' + linkedinId.postid
              )
            }
          )
        );
      },
      error => console.error( 'Error loading linkedin post ids: ', error )
    );
  }

  public gotoLinkedIn(): void {
    window.open( 'https://www.linkedin.com/company/pirmin-jung-ingenieure-ag/', '_blank' );
  }
}
