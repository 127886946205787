import { Component, HostBinding } from '@angular/core';
import { ILocalizedComponent } from 'src/app/ILocalizedComponent';
import { Title } from '@angular/platform-browser';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { homeAnimations } from 'src/app/_animations/home.animations';
import {
  IMcbJournalEintraegePreview,
  EModularContentBlock
} from 'src/rest-interfaces/IModularContent.field';
import { IEntryHome } from 'src/rest-interfaces/IEntryHome';
import { EntryService } from 'src/app/services/entry.service';

function shuffleArray<T extends Array<any>>( a: T ): T {
  for ( let i = a.length - 1; i > 0; i-- ) {
    const j = Math.floor( Math.random() * ( i + 1 ) );
    [ a[ i ], a[ j ] ] = [ a[ j ], a[ i ] ];
  }
  return a;
}

@Component( {
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [ homeAnimations ]
} )
export class HomeComponent implements ILocalizedComponent {
  private _home: IEntryHome;
  public set home( value: IEntryHome ) {
    if ( value && 'startseiteBild' in value ) {
      value.startseiteBild = shuffleArray( value.startseiteBild );
    }

    // DEBUG
    // if ( value && 'newsfeed' in value ) {
    //   value.newsfeed = [];
    // }
    // END DEBUG

    // check for journal preview and save a reference to it
    if ( value && 'modularContent' in value ) {
      const iJournalPreview = value.modularContent.findIndex(
        blk => blk.blocktype === EModularContentBlock.journalPreview
      );
      if ( iJournalPreview >= 0 ) {
        // removes journal preview block from modular content
        // and assigns it to separate variable
        this.sidebarJournal = value.modularContent[ iJournalPreview ] as IMcbJournalEintraegePreview;

        this.hasNews = value.newsfeed && value.newsfeed.length > 0;
        if ( this.hasNews ) {
          this.sidebarJournal.additionalClasses = [];
        } else {
          this.sidebarJournal.additionalClasses = [ 'hide-m', 'hide-l', 'hide-xl' ];
        }
      }
    }
    this._home = value;
  }
  public get home(): IEntryHome {
    return this._home;
  }

  public sidebarJournal: IMcbJournalEintraegePreview;

  @HostBinding( 'class.has-news' ) hasNews = false;

  constructor(
    private entries: EntryService,
    private title: Title,
    public navigationSvc: NavigationService,
    private scrollService: ScrollService
  ) {
    this.entries.customControllerUri<IEntryHome>( 'home' ).subscribe(
      t => {
        this.home = t;
        this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );
      }
    );
  }

  public getLocaleChangeUrl( targetLocale: string ): string {
    const locUrlProperty = 'url_' + targetLocale;
    if ( this.home && this.home.hasOwnProperty( locUrlProperty ) ) {
      return this.home[ locUrlProperty ];
    }
    return '/';
  }

  onScrollDown( event: Event ) {
    this.scrollService.triggerScrollToOffset( 'scrollDestination', -100 );
  }
}
