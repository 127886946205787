import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { NgXCookies } from 'src/app/services/ngx-cookies';
import { UrlHelper } from 'src/app/helpers/url.helper';

@Injectable()
export class ScrollPositionService {
  private basePath = '/';

  private readonly COOKIE_NAME_SCROLLPOS = '__pj_scroll';
  private _isScrolling = false;
  public getIsScrolling(): boolean {
    return this._isScrolling;
  }


  constructor(
    private router: Router,
    private location: Location,
    private scrollToSvc: ScrollToService
  ) {
    this.basePath = UrlHelper.getBasePathAbsolute();
  }

  public init(): void {
    window.addEventListener(
      'beforeunload',
      this.onBeforeUnload.bind( this )
    );

    this.router.events.subscribe(
      e => {
        const currentPath = this.location.path() || '/';
        const targetPath = e.hasOwnProperty( 'url' ) ? ( e as any ).url : undefined;

        // skip fresh page loads
        if ( currentPath === targetPath ) {
          return;
        }

        if ( e instanceof NavigationStart ) {
          this.pushScrollPos( currentPath );
        }
      }
    );
  }

  private onBeforeUnload(): void {
    const currentPath = this.location.path() || '/';

    this.pushScrollPos( currentPath );
  }

  private pushScrollPos( path: string ): void {
    const scrollY = ( window.scrollY ? window.scrollY.toString() : window.pageYOffset.toString() ) || '0';

    // remove /
    if ( path.charAt( 0 ) === '/' ) {
      path = path.substr( 1 );
    }
    const fullPath = this.basePath + path;

    NgXCookies.setCookie(
      this.COOKIE_NAME_SCROLLPOS + fullPath,
      scrollY,
      1, 'hours',
      undefined,
      '/'
    );
  }

  public popScrollPos(): void {
    this._isScrolling = true;

    setTimeout(
      () => {
        window.scrollTo( 0, 0 );

        // console.log( 'popScrollPos()', this.COOKIE_NAME_SCROLLPOS + this.basePath + this.location.path().replace( /^\//, '' ) );
        const cookieVal = NgXCookies.getCookie( this.COOKIE_NAME_SCROLLPOS + this.basePath + this.location.path().replace( /^\//, '' ) );
        let scrollY = Number.parseInt( cookieVal, 10 );
        if ( typeof scrollY !== 'number' || scrollY < 0 ) {
          scrollY = 0;
        }

        this.scrollToSvc.scrollTo(
          {
            offset: scrollY
          }
        );
        this._isScrolling = false;
      },
      500
    );
  }
}
