import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component( {
  selector: 'app-nav-icon',
  templateUrl: './nav-icon.component.html',
  styleUrls: [ './nav-icon.component.scss' ]
} )
export class NavIconComponent implements OnChanges {
  @Input() open: Boolean;

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'open':
          this.open = Boolean( changes.open.currentValue );
          break;
      }
    }
  }
}
