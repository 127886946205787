import { ITranslationMap } from './ITranslationMap.i18n';

export const translations: ITranslationMap = {
  // Home
  'zu weiteren Inhalten': 'more interesting content',

  // Filter & search
  'Suchen ...': 'Search ...',
  'Treffer': 'Matches',
  'Alle': 'All',
  'mehr Infos': 'more',
  'mehr lesen': 'read more',
  'mehr laden': 'load more',
  'mehr anzeigen': 'load more',

  // Projekt
  'Standort': 'Location',
  'Erbrachte Leistungen': 'Performed Services',
  'Bauherr': 'Owner',
  'Bauherrin': 'Owner',
  'Architektur': 'Architect',
  'Fertigstellung': 'Project Completion',
  'Kontakt': 'Contact',
  'Holzbau': 'Woodwork',
  'Fotografie': 'Photographer',
  'Wettbewerb': 'Contest',
  'Auszeichnungen': 'Awards',
  'Jahr': 'Year',
  'Ähnliche Projekte': 'Similar Projects',
  'Interessantes zum Projekt': 'Interesting posts about the project',

  // Journal
  'Artikel lesen': 'Read Article',
  'Autor': 'Author',
  'weitere Storys': 'more Stories'
};
