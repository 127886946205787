import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IMcbjClaimBlock } from 'src/rest-interfaces/journal/IModularContentJournal.field';
import { ModularContentHelper } from 'src/app/helpers/modular-content.helper';

@Component( {
  selector: 'app-journal-claim',
  templateUrl: './journal-claim.component.html'
} )
export class JournalClaimComponent implements OnChanges {
  @Input( 'content' ) content: IMcbjClaimBlock;
  public blockOrientationColClasses = ModularContentHelper.COL_CLASSES_LEFT;

  constructor() { }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'content':
          if ( changes.content.currentValue ) {
            const content = changes.content.currentValue as IMcbjClaimBlock;
            this.blockOrientationColClasses = ModularContentHelper.ClaimBlockOrientationToColClasses( content.position );
            this.content = content;
          } else {
            this.blockOrientationColClasses = ModularContentHelper.COL_CLASSES_LEFT;
          }
      }
    }
  }
}
