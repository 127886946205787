import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { IModularContent, EModularContentBlock, IMcbWettbewerbsgruppe } from 'src/rest-interfaces/IModularContent.field';
import { TimelineMax } from 'gsap';
import { scrollMagicDuration, fullHookPos, mobileHookPos } from '../_animations/animations.timings';

@Component( {
  selector: 'app-modular-content',
  templateUrl: './modular-content.component.html'
} )
export class ModularContentComponent implements OnInit, OnChanges, AfterViewInit {
  private _modularContent: IModularContent;
  @Input( 'modularContent' ) public set modularContent( value: IModularContent ) {
    if ( !value ) { return; }
    let prevWettbewerbgruppeIndex = -1;
    for ( let i = 0; i < value.length; ++i ) {
      const blk = value[ i ] as any as IMcbWettbewerbsgruppe;
      if ( blk.blocktype as string === EModularContentBlock.wettbewerbsgruppe ) {
        if ( prevWettbewerbgruppeIndex < 0 ) {
          prevWettbewerbgruppeIndex = i;
          blk.hasMainHeading = true;
        } else {
          blk.hasMainHeading = false;
        }
      } else {
        prevWettbewerbgruppeIndex = -1;
      }
    }
    this._modularContent = value;
  }
  public get modularContent(): IModularContent {
    return this._modularContent;
  }
  @ViewChild( 'scrollTrigger' ) trigger: ElementRef;

  public ready = false;
  public hookPos;
  public hookPosMobile = undefined;
  public setScrollMagic = true;

  public BlockType = EModularContentBlock;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  onScrollInit() {
    if ( !this.ready ) {
      this.onResize();
      this.ready = true;
    }
  }

  onScrollMagic() {
    const controller = new ScrollMagic.Controller();
    const blocks = this.trigger.nativeElement.querySelectorAll( '.scrollBlock' );
    for ( const block of blocks ) {
      const tween = new TimelineMax();
      if ( this.setScrollMagic ) {
        tween.from( block, scrollMagicDuration, { opacity: 0, y: '+=20px', clearProps: 'y' }, 0 );
      }
      new ScrollMagic.Scene( {
        triggerElement: block,
        triggerHook: this.hookPos,
      } )
        .setTween( tween )
        .addTo( controller );
    }
    this.setScrollMagic = false;
  }

  private getById( name: string ) {
    return this.trigger.nativeElement.querySelector( '#' + name );
  }

  @HostListener( 'window:resize' )
  onResize() {
    let hookMobile;
    if ( window.innerWidth >= 993 ) {
      hookMobile = false;
      this.hookPos = fullHookPos;
    } else {
      hookMobile = true;
      this.hookPos = mobileHookPos;
    }
    if ( this.hookPosMobile && !hookMobile || !this.hookPosMobile && hookMobile || this.hookPosMobile === undefined ) {
      this.hookPosMobile = hookMobile;
      this.onScrollMagic();
    }
  }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'modularContent':
          if ( changes.modularContent.currentValue ) {
            this.modularContent = changes.modularContent.currentValue;
          }
          break;
      }
    }
  }
}
