import { Component, Input } from '@angular/core';
import { IMcbBenefits } from 'src/rest-interfaces/IModularContent.field';
import { SliderConfigService } from 'src/app/services/slider-config.service';


@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html'
})
export class BenefitsComponent {
  @Input('content') content: IMcbBenefits;

  public activeId;

  constructor(
    public SliderConfigSvc: SliderConfigService,
  ){
    console.log(this.content);
  }

  public setActiveId(id) {
    if(this.activeId === id) {
      this.activeId = null;
    }
    else {
      this.activeId = id;
    }
  }

}
