import { Pipe, PipeTransform } from '@angular/core';
import { IEntryJournal } from 'src/rest-interfaces/IEntryJournal';

@Pipe({
  name: 'filterPipe',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filter: string) {
        if (filter === null || filter === '') {
          return items;
        }
        filter = filter.toLowerCase();

        return items.filter(
          item => {
            let result = false;
            if (item.title.toLowerCase().indexOf(filter) > -1) {
              result = true;
            }
            if (item.projektKategorie) {
              item.projektKategorie.filter(
                kategorie => {
                  if (kategorie.title) {
                    if (kategorie.title.toLowerCase().indexOf(filter) > -1) {
                      result = true;
                    }
                  }
                  if (kategorie.children) {
                    kategorie.children.filter(
                      childKat => {
                        if (childKat.title.toLowerCase().indexOf(filter) > -1) {
                          result = true;
                        }
                      }
                    );
                  }
                }
              );
            }
            if (item.mitarbeiterKategorie) {
              item.mitarbeiterKategorie.filter(
                kategorie => {
                  if (kategorie.title) {
                    if (kategorie.title.toLowerCase().indexOf(filter) > -1) {
                      result = true;
                    }
                  }
                  if (kategorie.children) {
                    kategorie.children.filter(
                      childKat => {
                        if (childKat.title.toLowerCase().indexOf(filter) > -1) {
                          result = true;
                        }
                      }
                    );
                  }
                }
              );
            }
            if (item.kategorie) {
              if (this.getJournalCategoriesStr(item).toLowerCase().indexOf(filter) > -1) {
                result = true;
              }
            }
            if (item.storyIntoLead) {
              if (item.storyIntoLead.toLowerCase().indexOf(filter) > -1) {
                result = true;
              }
            }
            return result;
          }
        );
    }

    public getJournalCategoriesStr( story: IEntryJournal ): string {
      return Object.keys( story.kategorie ).map(
        cat => cat
      ).join( ', ' );
    }
}
