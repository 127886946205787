import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { ITranslationMap } from './ITranslationMap.i18n';
import { LocaleService } from '../localization/locale.service';
// translation files
import { translations as t_en } from './translations.en.i18n';


export interface ITranslation {
  ch?: ITranslationMap;
  de?: ITranslationMap;
  en?: ITranslationMap;
}


const TRANSLATIONS: ITranslation = {
  ch: null,
  de: null,
  en: t_en
};


@Injectable()
export class TranslateSvc {
  private readonly CH_URL = 'https://cloud.ccm19.de/app.js?apiKey=aaa65b70f74b28025d6367d0ae3b6403e8dc870023d294ad&amp;domain=64c107314fffd948e20cf5f2&amp;lang=de_DE';
  private readonly DE_URL = 'https://cloud.ccm19.de/app.js?apiKey=aaa65b70f74b28025d6367d0ae3b6403e8dc870023d294ad&amp;domain=64c1092590134be6080a3182&amp;lang=de_DE';

  private scriptEl: HTMLScriptElement;

  constructor(
    private localeSvc: LocaleService
  ) {
    this.scriptEl = document.createElement( 'script' );
    let url = this.CH_URL;
    if(this.localeSvc.Locale === 'de') {
      url = this.DE_URL;
    }
    this.scriptEl.setAttribute( 'src', url );
    this.scriptEl.setAttribute( 'referrerpolicy', 'origin' );

    const body = document.getElementsByTagName( 'head' )[ 0 ];
    body.appendChild( this.scriptEl );
  }

  public t( value: string, locale?: string ): string {
    if ( typeof locale !== 'string' ) {
      locale = this.localeSvc.Locale;
    }
    if ( locale.length !== 2 ) {
      locale = 'ch';
    }

    if (
      TRANSLATIONS.hasOwnProperty( locale )
      && typeof TRANSLATIONS[ locale ] === 'object'
      && TRANSLATIONS[ locale ] !== null
      && TRANSLATIONS[ locale ].hasOwnProperty( value )
    ) {
      return TRANSLATIONS[ locale ][ value ] || value;
    } else {
      return value;
    }
  }
}


/**
 * Translation pipe for frontend-only translations.
 * @description Translation pipe usage:
 * - ```'Hello'|t``` uses current locale
 * - ```'Hello'|t:'en'``` uses 'en' locale
 *
 * Translatable strings can be defined in a separate
 * file for each locale.
 * Example: ```translations.en.i18n.ts```
 *
 * @export
 * @class TranslatePipe
 * @implements {PipeTransform}
 */
@Pipe( {
  name: 't',
  pure: true
} )
export class TranslatePipe implements PipeTransform {
  constructor(
    private transSvc: TranslateSvc
  ) { }

  public transform( value: string, locale?: string ): string {
    return this.transSvc.t( value, locale );
  }
}
