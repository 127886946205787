import { Component, Input } from '@angular/core';
import { IMcbZeitstrahl } from 'src/rest-interfaces/IModularContent.field';
import { SliderConfigService } from 'src/app/services/slider-config.service';


@Component({
  selector: 'app-zeitstrahl',
  templateUrl: './zeitstrahl.component.html'
})
export class ZeitstrahlComponent {
  @Input('content') content: IMcbZeitstrahl;
  constructor(
    public SliderConfigSvc: SliderConfigService,
  ){
  }

}
