import { Component, HostListener, ElementRef, Input, OnChanges, SimpleChanges, HostBinding, ViewChild, AfterViewInit } from '@angular/core';

@Component( {
  selector: 'app-video',
  templateUrl: 'video.component.html',
  styleUrls: [ 'video.component.scss' ]
} )
export class VideoComponent implements OnChanges, AfterViewInit {
  private static readonly VIDEO_CLASS_PAUSED = 'video__paused';
  private static readonly VIDEO_CLASS_PLAYING = 'video__playing';

  @ViewChild( 'videoEl' ) videoEl: ElementRef<HTMLVideoElement>;

  @Input() public videoUrl: string;
  @HostBinding( 'class' ) hostClasses = VideoComponent.VIDEO_CLASS_PAUSED;

  @HostListener( 'click' ) onclick() {
    if ( this.videoEl.nativeElement.paused ) {
      this.play();
    }
  }

  constructor(
    private el: ElementRef<HTMLDivElement>
  ) { }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'videoUrl':
          const videoUrl = changes.videoUrl.currentValue;
          if ( typeof videoUrl === 'string' && videoUrl.length > 0 ) {
            this.videoUrl = videoUrl;
          }
          break;
      }
    }
  }

  ngAfterViewInit() {
    this.pause();
  }

  private togglePlayPause(): void {
    if ( !this.videoEl.nativeElement.paused ) {
      this.pause();
    } else {
      this.play();
    }
  }

  public play(): void {
    this.hostClasses = VideoComponent.VIDEO_CLASS_PLAYING;

    if ( this.videoEl.nativeElement ) {
      this.videoEl.nativeElement.setAttribute( 'controls', 'true' );
      this.videoEl.nativeElement.play();
    }
  }

  public pause(): void {
    this.hostClasses = VideoComponent.VIDEO_CLASS_PAUSED;

    if ( this.videoEl.nativeElement ) {
      this.videoEl.nativeElement.pause();
      this.videoEl.nativeElement.removeAttribute( 'controls' );
    }

  }
}
