import { Component, OnInit, Input } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { LocaleService } from 'src/app/localization/locale.service';
import { IMcbJournalEintraegePreview } from 'src/rest-interfaces/IModularContent.field';


@Component( {
  selector: 'app-journal-preview',
  templateUrl: './journal-preview.component.html'
} )
export class JournalPreviewComponent implements OnInit {
  @Input( 'content' ) content: IMcbJournalEintraegePreview;
  public EColor = EColor;

  constructor(
    public localeSvc: LocaleService
  ) { }

  ngOnInit() {
  }

}
