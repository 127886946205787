import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IMcbjVideoBlock } from 'src/rest-interfaces/journal/IModularContentJournal.field';
import { ModularContentHelper } from 'src/app/helpers/modular-content.helper';

@Component( {
  selector: 'app-journal-video-block',
  templateUrl: './journal-video-block.component.html'
} )
export class JournalVideoBlockComponent implements OnChanges {
  @Input( 'content' ) content: IMcbjVideoBlock;
  public blockOrientationColClasses = ModularContentHelper.COL_IMAGE_CLASSES_LEFT;

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'content':
          if ( changes.content.currentValue ) {
            const content = changes.content.currentValue as IMcbjVideoBlock;
            this.blockOrientationColClasses = ModularContentHelper.ImageBlockOrientationToColClasses( content.position );
            this.content = content;
          } else {
            this.blockOrientationColClasses = ModularContentHelper.COL_IMAGE_CLASSES_LEFT;
          }
      }
    }
  }
}
