import { Component } from '@angular/core';
import { IEntryGeneralSubsite } from 'src/rest-interfaces/general-subsite/IEntryGeneralSubsite';
import { EntryService } from 'src/app/services/entry.service';
import { LocaleService } from '../../localization/locale.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SliderConfigService } from 'src/app/services/slider-config.service';
import { generalAnimations } from 'src/app/_animations/general.animations';
import { ILocalizedComponent } from 'src/app/ILocalizedComponent';

@Component( {
  selector: 'app-general-subsite',
  templateUrl: './general-subsite.component.html',
  animations: [ generalAnimations ]
} )
export class GeneralSubsiteComponent implements ILocalizedComponent {
  public content: IEntryGeneralSubsite;

  constructor(
    private entrySvc: EntryService,
    private localeSvc: LocaleService,
    private route: ActivatedRoute,
    private title: Title,
    public SliderConfigSvc: SliderConfigService
  ) {
    this.route.url.subscribe(
      () => {
        this.entrySvc.uri<IEntryGeneralSubsite>(
          this.localeSvc.RouterUri
        ).subscribe(
          t => {
            this.content = t;
            this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );
          },
          () => { this.localeSvc.navigateToHome(); }
        );
      }
    );
  }

  public getLocaleChangeUrl( targetLocale: string ): string {
    const locUrlProperty = 'url_' + targetLocale;
    if ( this.content && this.content.hasOwnProperty( locUrlProperty ) ) {
      return this.content[ locUrlProperty ];
    }
  }
}
