import { Injectable } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { Observable } from 'rxjs';

@Injectable()
export class ScrollService {

  constructor( private _scrollToService: ScrollToService ) { }

  public triggerScrollTo( scrollTarget: string, top: boolean = false ): Observable<any> {
    const config: ScrollToConfigOptions = {
      target: scrollTarget,
      duration: 500,
      easing: 'easeOutQuad'
    };

    if ( top ) {
      config.duration = 850;
    }

    return this._scrollToService.scrollTo( config );
  }

  public triggerScrollToOffset( scrollTarget: string, offset: number ): Observable<any> {
    const config: ScrollToConfigOptions = {
      target: scrollTarget,
      duration: 500,
      easing: 'easeOutQuad',
      offset: offset
    };

    return this._scrollToService.scrollTo( config );
  }
}
