import { Component, Input } from '@angular/core';
import { IMcbProjektePreview } from 'src/rest-interfaces/IModularContent.field';
import { EColor } from 'src/rest-interfaces/IColor';
import { LocaleService } from 'src/app/localization/locale.service';
import { ELocale } from 'src/app/localization/ELocale';

@Component( {
  selector: 'app-projects-preview',
  templateUrl: './projects-preview.component.html'
} )
export class ProjectsPreviewComponent {
  @Input() content: IMcbProjektePreview;
  public EColor = EColor;

  public projektePageLink = '';

  constructor(
    localeSvc: LocaleService
  ) {
    this.projektePageLink = localeSvc.LocalePathPre
      + ( localeSvc.Locale === ELocale.en ? '/projects' : '/projekte' );
  }

  public getProjectGridCssCellClasses( i: number ): string {

    const rowNoL = Math.floor( i / 3 ) + 1;
    const colNoL = i % 3;

    const colStart = Math.floor( colNoL * 7.5 ) + 2;
    const colEnd = Math.floor( ( colNoL + 1 ) * 7.5 ) + ( colNoL === 1 ? 0 : 1 );

    const gridClasses = [
      'row-s-' + ( i + 1 ).toString(),
      'col-s-3-22',
      'row-l-' + rowNoL,
      'col-l-' + colStart.toString() + '-' + colEnd.toString(),
      'row-xl-' + rowNoL,
      'col-xl-' + colStart.toString() + '-' + colEnd.toString(),
    ];

    if ( colNoL === 1 ) {
      gridClasses.push( 'mov-m-05-7 mov-l-05-7 mov-xl-05-7' );
    }

    return gridClasses.join( ' ' );
  }
}
