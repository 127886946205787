import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { API_BASE_URL } from './api.config';
import { LocaleService } from '../localization/locale.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private locale: LocaleService
  ) { }

  public post<T = any, U = any>( uri: string, body: T ): Observable<U> {
    if ( typeof uri !== 'string' ) {
      uri = '';
    } else if ( uri.charAt( 0 ) === '/' ) {
      uri = uri.substr( 1 );
    }
    // console.log( 'uri', uri, 'LocalePath', this.locale.ApiLocaleBasePath );
    return this.http.post<U>(
      API_BASE_URL + uri,
      body,
      {
        headers: new HttpHeaders().set( 'X-Requested-With', 'XMLHttpRequest' ),
        withCredentials: true
      }
    );
  }

  public get<T>( uri: string ): Observable<T> {
    if ( typeof uri !== 'string' ) {
      uri = '';
    } else if ( uri.charAt( 0 ) === '/' ) {
      uri = uri.substr( 1 );
    }
    // console.log( 'Api.get(...) uri', uri );
    // console.log( 'LocalePath', this.locale.ApiLocaleBasePath );
    // console.log( 'GET ' + this.locale.ApiLocaleBasePath + API_BASE_URL + uri );
    return this.http.get<T>(
      API_BASE_URL + uri,
      {
        headers: new HttpHeaders().set( 'X-Requested-With', 'XMLHttpRequest' ),
        withCredentials: true
      }
    );
  }
}
