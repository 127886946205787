import { Injectable } from '@angular/core';
import { INavigation, INavigationCurrent, INavLinkEintrag } from '../../rest-interfaces/INavigation';
import { ApiService } from '../api/api.service';
import { LocaleService } from '../localization/locale.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UrlHelper } from '../helpers/url.helper';

@Injectable()
export class NavigationService {
  private loadingNavigation = false;
  private _navigation: { entry: INavigation; } = {
    entry: null
  };
  public get Navigation(): { entry: INavigation; } {
    return this._navigation;
  }

  private _currentNavEntry: INavLinkEintrag & INavigationCurrent = null;
  public get CurrentNavEntry(): INavLinkEintrag & INavigationCurrent {
    return this._currentNavEntry;
  }

  private routeSubscription: Subscription;

  constructor(
    private API: ApiService,
    private localeSvc: LocaleService,
    private router: Router
  ) { }

  public loadNavigationData(): void {
    if ( this.loadingNavigation ) {
      return;
    }
    this.loadingNavigation = true;
    // zip with router events not possible, because <app-navigation/> is outside of <router-outlet/>
    this.API.get<INavigation>( '/navigation' ).subscribe(
      navigation => {
        this.removeMainNavEntriesWithEmptyChildren( navigation );

        this._navigation.entry = navigation;
        if ( !this.routeSubscription ) {
          this.routeSubscription = this.router.events.pipe(
            filter( e => e instanceof NavigationEnd )
          ).subscribe(
            () => {
              this.updateNavigationCurrentPage();
            }
          );
        }
        this.updateNavigationCurrentPage();
        this.loadingNavigation = false;
      }
    );
  }

  private updateNavigationCurrentPage() {
    if ( !this._navigation || !this._navigation.entry ) {
      return;
    }
    this.findAndSetCurrentNavEntry();
  }

  private findAndSetCurrentNavEntry(): void {
    // remove leading and trailing '/'
    const currentLocationPath = this.localeSvc.getCurrentLocationPath().replace( /^\/|\/$/g, '' );
    const currentLocationPathSegments = currentLocationPath.split( '/' );

    let highestSegmMatchCount = 0;
    let currentBestMatch: INavigationCurrent = null;
    for ( const n of this.Navigation.entry.navigationMenu ) {
      n.isCurrent = false;
      const segments = n.eintrag[ 0 ].uri.replace( '__home__', '' ).split( '/' );
      const segMatchCount = UrlHelper.getMatchingSegmentsCount( segments, currentLocationPathSegments );
      if ( segMatchCount > highestSegmMatchCount ) {
        highestSegmMatchCount = segMatchCount;
        currentBestMatch = n;
      }
      // traverse children
      for ( const sub of n.unterseiten ) {
        sub.isCurrent = false;
        const subSegments = sub.linkUri.replace( new RegExp( '^' + this.localeSvc.LocaleHomeUrl ), '' ).replace( /^\//, '' ).split( '/' );
        const subSegMatchCount = UrlHelper.getMatchingSegmentsCount( subSegments, currentLocationPathSegments );
        if ( subSegMatchCount > highestSegmMatchCount ) {
          highestSegmMatchCount = subSegMatchCount;
          currentBestMatch = sub;
        }
      }
    }
    for ( const n of this.Navigation.entry.navigationUntermenu ) {
      n.isCurrent = false;
      const segments = n.eintrag[ 0 ].uri.replace( '__home__', '' ).split( '/' );
      const segMatchCount = UrlHelper.getMatchingSegmentsCount( segments, currentLocationPathSegments );
      if ( segMatchCount > highestSegmMatchCount ) {
        highestSegmMatchCount = segMatchCount;
        currentBestMatch = n;
      }
    }

    if ( currentBestMatch ) {
      currentBestMatch.isCurrent = true;
      this._currentNavEntry = currentBestMatch as INavLinkEintrag & INavigationCurrent;
    }
  }

  private removeMainNavEntriesWithEmptyChildren( nav: INavigation ): void {
    if ( nav.navigationMenu ) {
      nav.navigationMenu = nav.navigationMenu.filter(
        entry => !Array.isArray( entry.eintrag ) || entry.eintrag.length > 0
      )
    }
  }
}
