import {Component} from '@angular/core';
import {EntryService} from 'src/app/services/entry.service';
import {IEntryTeam, IFilterEntryTeamMember} from 'src/rest-interfaces/ueber-uns/IEntryTeam';
import {LocaleService} from 'src/app/localization/locale.service';
import {IFilterCategory, IFilterCategoryBase} from 'src/rest-interfaces/ICategory';
import {ILocalizedComponent} from 'src/app/ILocalizedComponent';
import {Title} from '@angular/platform-browser';
import {generalAnimations} from 'src/app/_animations/general.animations';
import {NgXCookies} from 'src/app/services/ngx-cookies';
import {ScrollPositionService} from 'src/app/services/scroll-position/scroll-position.service';

interface IFilterModelTeam {
  term: string;
  selectedMainFilter: IFilterCategory;
  selectedSubFilters: IFilterCategory[];
}

@Component({
  selector: 'app-ueber-uns-team',
  templateUrl: './team.component.html',
  animations: [generalAnimations]
})
export class TeamComponent implements ILocalizedComponent {
  private readonly COOKIE_FILTER_NAME = '__teamfltr';

  public content: IEntryTeam;
  public teamMembers: Array<IFilterEntryTeamMember>;
  public filteredTeamMembers: Array<IFilterEntryTeamMember>;
  public mainFilterCategories: IFilterCategory[];
  public showAll = true;
  public filterModel: IFilterModelTeam = {
    term: '',
    selectedMainFilter: null,
    selectedSubFilters: []
  };

  constructor(
    private entrySvc: EntryService,
    private localeSvc: LocaleService,
    private title: Title,
    scrollPosSvc: ScrollPositionService
  ) {
    this.entrySvc.customControllerUri<IEntryTeam>(this.localeSvc.RouterUri).subscribe(
      teamEntry => {
        scrollPosSvc.popScrollPos();
        // console.log( teamEntry );
        this.content = teamEntry;
        this.title.setTitle('PIRMIN JUNG - ' + (typeof teamEntry.metaTitle === 'string' && teamEntry.metaTitle.length > 0 ? teamEntry.metaTitle : teamEntry.title));
        this.mainFilterCategories = Object.keys(teamEntry.mitarbeiterFilter).map(
          key => {
            const standort: IFilterCategory = Object.assign({}, teamEntry.mitarbeiterFilter[key] as any);
            if (
              teamEntry.mitarbeiterFilter[key].hasOwnProperty('children')
              && teamEntry.mitarbeiterFilter[key].children
            ) {
              standort.children = Object.keys(teamEntry.mitarbeiterFilter[key].children).map(
                childKey => teamEntry.mitarbeiterFilter[key].children[childKey] as IFilterCategoryBase
              );
            }
            return standort;
          }
        );
        this.teamMembers = Object.keys(teamEntry.teamElemente).map(
          key => {
            const teamMember: IFilterEntryTeamMember = Object.assign({}, teamEntry.teamElemente[key] as any);
            teamMember.mitarbeiterKategorie = Object.keys(teamEntry.teamElemente[key].mitarbeiterKategorie).map(
              katKey => {
                if (
                  teamEntry.teamElemente[key].mitarbeiterKategorie[katKey].hasOwnProperty('children')
                  && teamEntry.teamElemente[key].mitarbeiterKategorie[katKey].children
                ) {
                  teamMember.mitarbeiterKategorie[katKey].children = Object.keys(
                    teamEntry.teamElemente[key].mitarbeiterKategorie[katKey].children).map(
                    childKatKeyKey => teamEntry.teamElemente[key].mitarbeiterKategorie[katKey].children[childKatKeyKey]
                  );
                }
                return teamEntry.teamElemente[key].mitarbeiterKategorie[katKey];
              }
            );
            return teamMember;
          }
        ).sort(
          (a, b) => {
            const aLastname = this.getLastnameFromTeamMember(a.title);
            const bLastname = this.getLastnameFromTeamMember(b.title);
            return aLastname.localeCompare(bLastname);
          }
        );
        this.filteredTeamMembers = this.teamMembers;

        this.loadFilterModelFromCookie();
      },
      () => {
        this.localeSvc.navigateToHome();
      }
    );
  }


  private loadFilterModelFromCookie(): void {
    if (NgXCookies.exists(this.COOKIE_FILTER_NAME)) {
      const cookieFilterModel = JSON.parse(NgXCookies.getCookie(this.COOKIE_FILTER_NAME));
      if (
        typeof cookieFilterModel === 'object'
        && cookieFilterModel.hasOwnProperty('term')
      ) {
        this.filterModel = cookieFilterModel as IFilterModelTeam;
        if (this.filterModel.selectedSubFilters === null) {
          this.filterModel.selectedSubFilters = [];
        }

        this.updateFilteredTeamMembers();
      } else {
        NgXCookies.deleteCookie(this.COOKIE_FILTER_NAME);
      }
    }
  }

  public activeSubFiltersContainsCategory(category: IFilterCategory) {
    return this.filterModel.selectedSubFilters.length > 0
      && this.filterModel.selectedSubFilters
        .filter(
          subFilter => subFilter.id === category.id
        ).length > 0;
  }

  public teamMemberMatchesAllActiveFilters(teamMember: IFilterEntryTeamMember) {
    return this.filterModel.selectedSubFilters.length === 0 ||
      this.filterModel.selectedSubFilters.every(
        activeSubFilter => teamMember.mitarbeiterKategorie.some(
          mitarbeiterKategorie => mitarbeiterKategorie.children && mitarbeiterKategorie.children.some(
            subCategory => activeSubFilter.id === subCategory.id)
        )
      );
  }

  public onMainFilterSelected(selectedMainFilter?: IFilterCategory) {
    this.showAll = (typeof (selectedMainFilter) === 'undefined' ? true : false);
    if (this.filterModel.selectedMainFilter === selectedMainFilter || selectedMainFilter === undefined) {
      this.filteredTeamMembers = this.teamMembers;
      this.filterModel.selectedMainFilter = null;
      this.filterModel.selectedSubFilters = [];
    } else {
      this.filterModel.selectedMainFilter = selectedMainFilter;
    }

    this.updateCookie();
  }

  public toggleSubFilter(selectedSubFilter: IFilterCategory) {
    if (this.activeSubFiltersContainsCategory(selectedSubFilter)) {
      this.filterModel.selectedSubFilters = this.filterModel.selectedSubFilters.filter(
        filter => filter.id !== selectedSubFilter.id
      );
    } else {
      // only 1 sub filter of the same branch is allowed!
      const selectedBranchSlug = selectedSubFilter.uri.split('/')[0];
      for (let i = this.filterModel.selectedSubFilters.length - 1; 0 <= i; --i) {
        const f = this.filterModel.selectedSubFilters[i];
        if (f.uri.split('/')[0] === selectedBranchSlug) {
          this.filterModel.selectedSubFilters.splice(i, 1);
        }
      }

      this.filterModel.selectedSubFilters.push(selectedSubFilter);
    }
  }

  public updateFilteredTeamMembers() {
    if (this.filterModel.selectedSubFilters.length < 1) {
      this.filteredTeamMembers = this.teamMembers;
    } else {
      this.filteredTeamMembers = this.teamMembers.filter(
        teaMember => this.teamMemberMatchesAllActiveFilters(teaMember)
      );
    }
  }

  public onSubFilterSelected(selectedSubfilter: IFilterCategory) {
    this.toggleSubFilter(selectedSubfilter);
    this.updateFilteredTeamMembers();
    this.updateCookie();
  }

  public updateCookie() {
    const filterModel: IFilterModelTeam = JSON.parse(JSON.stringify(this.filterModel));
    if (filterModel.selectedMainFilter && filterModel.selectedMainFilter.children) {
      delete filterModel.selectedMainFilter.children;
    }
    NgXCookies.setCookie(
      this.COOKIE_FILTER_NAME,
      JSON.stringify(filterModel),
      1, 'hours'
    );
  }

  public getLocaleChangeUrl(targetLocale: string): string {
    const locUrlProperty = 'url_' + targetLocale;
    if (this.content && this.content.hasOwnProperty(locUrlProperty)) {
      return this.content[locUrlProperty];
    }
  }

  private getLastnameFromTeamMember(name: string): string {
    if (typeof name !== 'string') {
      return '';
    }
    const parts = name.split(/\s+/);
    if (parts.length > 0) {
      return parts[parts.length - 1];
    }
    return name;
  }
}
