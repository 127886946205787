import { Component, Input } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcbLinkBanner } from 'src/rest-interfaces/IModularContent.field';

@Component({
  selector: 'app-link-banner',
  templateUrl: './link-banner.component.html'
})
export class LinkBannerComponent {
  @Input('content') content: IMcbLinkBanner;
  public EColor = EColor;
}
