import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Breadcrumb } from '../breadcrumbs/Breadcrumb';
import { filter } from 'rxjs/operators';
import { LocaleService } from '../localization/locale.service';
import { UrlHelper } from '../helpers/url.helper';
import { ELocale } from '../localization/ELocale';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';


@Injectable()
export class SucheService {

  public error: string;
  public searchTerm: string;
  public searchResults: any[] = [];

  constructor(
    private API: ApiService,
  ) {

  }

  suchen() {
    console.log(this.searchTerm);

    this.API.get(
      '/suche/' + this.searchTerm
    ).subscribe(
      (response: any) => {
        console.log(response);

        this.searchResults = response;
        this.error = '';
      },
      err => { 
        console.error( err );
      },
      () => {

      }
    );
  }

}
