import { Component, Input } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcbjKontaktBlock } from 'src/rest-interfaces/journal/IModularContentJournal.field';

@Component( {
  selector: 'app-journal-kontakt',
  templateUrl: './journal-kontakt.component.html'
} )
export class JournalKontaktComponent {
  @Input( 'content' ) content: IMcbjKontaktBlock;
  public EColor = EColor;
}
