import {Component} from '@angular/core';
import {EntryService} from 'src/app/services/entry.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IEntryTeamMember} from 'src/rest-interfaces/ueber-uns/IEntryTeam';
import {LocaleService} from 'src/app/localization/locale.service';
import {ILocalizedComponent} from 'src/app/ILocalizedComponent';
import {generalAnimations} from 'src/app/_animations/general.animations';
import {Title} from '@angular/platform-browser';
import {saveAs} from 'file-saver'
import {UrlHelper} from "../../../../helpers/url.helper";

@Component({
  selector: 'app-ueber-uns-team-member',
  templateUrl: './team-member.component.html',
  animations: [generalAnimations]
})
export class TeamMemberComponent implements ILocalizedComponent {
  public content: IEntryTeamMember;
  public basePath = UrlHelper.getBasePathAbsolute()
  public qrCode: string
  public contactDownloadIcon = 'assets/pics/PJ-Icon-Download.svg'
  public hasTouch = false;
  constructor(
    private entrySvc: EntryService,
    private localeSvc: LocaleService,
    private route: ActivatedRoute,
    private title: Title,
    private router: Router
  ) {
    if(window.matchMedia('(hover:none)').matches){
      this.hasTouch = true;
    }
    this.route.url.subscribe(
      () => {
        this.entrySvc.customControllerUri<IEntryTeamMember>(
          this.localeSvc.RouterUri
        ).subscribe(
          t => {
            this.content = t;
            this.title.setTitle('PIRMIN JUNG - ' + (typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title));

            fetch('actions/contact/contact/qrcode?teamId=' + this.content.id).then(async (response) => {
               response.json().then((responseJson)=>{
                this.qrCode = responseJson.qrcode
               });
            })
          },
          () => {
            this.localeSvc.navigateToHome();
          }
        );
      }
    );
  }

  public getLocaleChangeUrl(targetLocale: string): string {
    const locUrlProperty = 'url_' + targetLocale;
    if (this.content && this.content.hasOwnProperty(locUrlProperty)) {
      return this.content[locUrlProperty];
    }
  }

  public async saveVCF() {
    fetch('actions/contact/contact/vcf?teamId=' + this.content.id, {method: 'POST'}).then(async (response) => {
      const responseJson = await response.json()
      console.log(responseJson)
      saveAs(new Blob([responseJson.vcard],{type:'text/vcard'}), this.content.title+'.vcf')
    })
  }
}
