import { Component, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FooterService } from '../services/footer.service';
import { TimelineMax } from 'gsap';
import { scrollMagicDuration, footerDelay, fullHookPos, mobileHookPos } from '../_animations/animations.timings';
import { LocaleService } from '../localization/locale.service';
import { ApiService } from 'src/app/api/api.service';
import { first } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements AfterViewInit {
  @ViewChild('footerTrigger') trigger: ElementRef;

  public hookPos;
  public hookPosMobile = undefined;
  public setScrollMagic = true;


  constructor(
    public footerSvc: FooterService,
    public localeSvc: LocaleService,
    public loginSvc: LoginService,
  ) {
  }

  ngAfterViewInit() {
    this.onResize();
  }

  onScrollMagic() {
    const controller = new ScrollMagic.Controller();
    const tween = new TimelineMax();
    if ( this.setScrollMagic ) {
      tween.from(this.trigger.nativeElement, scrollMagicDuration, { delay: footerDelay, opacity: 0, y: '+=20px', clearProps: 'y' }, 0);
      this.setScrollMagic = false;
    }

    new ScrollMagic.Scene({
      triggerElement: this.trigger.nativeElement,
      triggerHook: this.hookPos,
    })
      .setTween(tween)
      .addTo(controller);
    // .addIndicators({name: 'test'});
  }

  @HostListener('window:resize')
  onResize() {
    let hookMobile;
    if (window.innerWidth >= 993) {
      hookMobile = false;
      this.hookPos = fullHookPos + 0.05;
    } else {
      hookMobile = true;
      this.hookPos = mobileHookPos + 0.05;
    }
    if (this.hookPosMobile && !hookMobile || !this.hookPosMobile && hookMobile || this.hookPosMobile === undefined) {
      this.hookPosMobile = hookMobile;
      this.onScrollMagic();
    }
  }
}
