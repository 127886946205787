import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EntryService } from 'src/app/services/entry.service';
import { IEntryProjekte, IEntryProjekteItemSimple } from 'src/rest-interfaces/projekte/IEntryProjekte';
import { IFilterCategories, IFilterCategory, ICategories } from 'src/rest-interfaces/ICategory';
import { ILocalizedComponent } from 'src/app/ILocalizedComponent';
import { generalAnimations } from 'src/app/_animations/general.animations';
import { LocaleService } from 'src/app/localization/locale.service';
import { IFilterModelProjekte } from 'src/rest-interfaces/projekte/IFilterModelProjekte';
import { ApiService } from 'src/app/api/api.service';
import { NgXCookies } from 'src/app/services/ngx-cookies';
import { ScrollPositionService } from 'src/app/services/scroll-position/scroll-position.service';
import { ProjekteFilterHelper } from './projekte-filter.helper';
import { fromEvent, interval } from 'rxjs';
import { debounce, filter } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';

interface IResponseFilterProjekte {
  found: number;
  entries: Array<IEntryProjekteItemSimple>;
}

@Component( {
  selector: 'app-projekte',
  templateUrl: './projekte.component.html',
  animations: [ generalAnimations ]
} )
export class ProjekteComponent implements ILocalizedComponent, AfterViewInit {
  // MUSS ZWINGEND gleich sein wie in ./modules/restapi/actions/projekte/ViewFilter.php
  private readonly ENTRIES_PER_PAGE = 24;


  private readonly COOKIE_FILTER_NAME = '__projfltr';

  @ViewChild( 'termInput' ) termInput: ElementRef;


  public content: IEntryProjekte;

  public filteredProjekte: Array<IEntryProjekteItemSimple>;
  public filteredProjekteCount = 0;
  public filterCategories: IFilterCategories;
  public showChildrenCat: IFilterCategory;

  public selectedCategoryTags: IFilterCategories = [];

  public hasMorePages = false;
  public isLoadingMore = false;

  /**
   * Only used for API request to get filtered projects
   */
  public filterModel: IFilterModelProjekte = {
    term: '',
    categories: {},
    page: 1,
    loadbefore: false
  };

  constructor(
    private API: ApiService,
    private entrySvc: EntryService,
    private localeSvc: LocaleService,
    private title: Title,
    scrollPosSvc: ScrollPositionService,
    public loginSvc: LoginService,
  ) {
    this.loadFilterModelFromCookie();

    this.entrySvc.customControllerUri<IEntryProjekte>( this.localeSvc.RouterUri ).subscribe(
      t => {
        this.content = t;
        this.filterCategories = this.getFilterCategoriesAsArray( this.content.projekteFilter );

        this.selectCategoriesFromFilterModel();
        this.loadFilteredProjects( true, true );

        scrollPosSvc.popScrollPos();

        this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );
      },
      err => {
        console.error( err );
        this.localeSvc.navigateToHome();
      }
    );
  }

  ngAfterViewInit(): void {
    this.attachTermKeyupListener();
  }

  private attachTermKeyupListener(): void {
    fromEvent( this.termInput.nativeElement, 'keyup' ).pipe(
      filter(
        ( e: KeyboardEvent ) => e.keyCode !== 13
      )
    ).pipe(
      debounce( () => interval( 800 ) )
    ).subscribe(
      e => {
        this.loadFilteredProjects( true );
      }
    );
  }

  private loadFilterModelFromCookie(): void {
    if ( NgXCookies.exists( this.COOKIE_FILTER_NAME ) ) {
      const cookieFilterModel = JSON.parse( NgXCookies.getCookie( this.COOKIE_FILTER_NAME ) );
      if (
        typeof cookieFilterModel === 'object'
        && cookieFilterModel.hasOwnProperty( 'term' )
        && (
          !cookieFilterModel.hasOwnProperty( 'categories' )
          || Object.keys( cookieFilterModel.categories ).length === 0
          || Object.keys( cookieFilterModel.categories )[ 0 ] !== 'undefined'
        )
      ) {
        this.filterModel = cookieFilterModel as IFilterModelProjekte;

        if(cookieFilterModel.page > 1) {
          NgXCookies.deleteCookie( this.COOKIE_FILTER_NAME );
        }
      }
      else {
        NgXCookies.deleteCookie( this.COOKIE_FILTER_NAME );
      }
    }
  }

  private selectCategoriesFromFilterModel(): void {
    if (
      !this.filterModel.categories
      || Object.keys( this.filterModel.categories ).length === 0
    ) {
      return;
    }

    ProjekteFilterHelper.selectAllCategoriesByFilter(
      this.filterCategories,
      this.filterModel.categories,
      this.selectedCategoryTags
    );

    // show children of last selected category
    let selectedTopCat;
    for ( const c of this.filterCategories ) {
      if ( c.selected ) {
        selectedTopCat = c;
      }
    }
    if ( selectedTopCat ) {
      this.showChildrenCat = selectedTopCat;
    }
  }

  public isCatFilterEmpty(): boolean {
    return Object.keys( this.filterModel.categories ).length === 0 && !this.showChildrenCat;
  }

  public onCatTreeClear() {

    this.showChildrenCat = null;
    this.filterModel.categories = {};

    this.unselectAllCategories();

    this.loadFilteredProjects( true );
  }

  private unselectAllCategories() {
    this.selectedCategoryTags = [];
    ProjekteFilterHelper.deselectAllCategories( this.filterCategories );
  }

  public onSelectedCategoryTagClick( sc: IFilterCategory ): void {
    const branch = ProjekteFilterHelper.findCategoryBranchArrFromChild( sc, this.filterCategories );
    this.onCatBranchSelected.apply( this, branch );
  }

  /**
   * Sets the filter for a top category, if the
   * top category has no children
   * otherwise set `showChildrenCat`
   *
   * @param {IFilterCategory} c
   * @memberof ProjekteComponent
   */
  public onCatTopSelected( c: IFilterCategory ): void {
    // top level category has children, therefore skip it
    if ( Array.isArray( c.children ) && c.children.length > 0 ) {
      // toggle between category and 'Alle'
      this.showChildrenCat = ( this.showChildrenCat === c ? null : c );
      return;
    }

    // top level category with no children can be toggled, therefore remove
    // it from filtermodel if already selected
    if ( c.selected ) {
      ProjekteFilterHelper.removeCategoryBranch( [ c ], this.filterModel.categories );
      this.showChildrenCat = null;
    } else {
      // initialize filter categories obj if undefined
      if ( typeof this.filterModel.categories !== 'object' ) {
        this.filterModel.categories = {};
      }

      c.selected = true;
      ProjekteFilterHelper.addCategoryBranch( [ c ], this.filterModel.categories );
    }

    this.loadFilteredProjects( true );
  }

  /**
   * Sets the filter for a given category branch
   *
   * @param {...IFilterCategories} catsTopToBottom
   * @returns {void}
   * @memberof ProjekteComponent
   */
  public onCatBranchSelected( ...catsTopToBottom: IFilterCategories ): void {
    // console.log( catsTopToBottom );
    if ( !Array.isArray( catsTopToBottom ) || catsTopToBottom.length === 0 ) { return; }

    // initialize filter categories obj if undefined
    if ( typeof this.filterModel.categories !== 'object' ) {
      this.filterModel.categories = {};
    }

    // always show children of current branch root category
    this.showChildrenCat = catsTopToBottom[ 0 ];

    const deepestCat = catsTopToBottom[ catsTopToBottom.length - 1 ];
    if ( deepestCat.selected ) {
      // deselect branch
      deepestCat.selected = false;
      // remove from tags array
      const removeTagIndex = this.selectedCategoryTags.findIndex( c => c.slug === deepestCat.slug );
      if ( removeTagIndex >= 0 ) { this.selectedCategoryTags.splice( removeTagIndex, 1 ); }
      // remove from filter model
      ProjekteFilterHelper.removeCategoryBranch( catsTopToBottom, this.filterModel.categories );
    } else {
      // select branch
      deepestCat.selected = true;
      // add to tags array
      const existingTagIndex = this.selectedCategoryTags.findIndex( c => c.slug === deepestCat.slug );
      if ( existingTagIndex < 0 ) { this.selectedCategoryTags.push( deepestCat ); }
      // add to filter model
      ProjekteFilterHelper.addCategoryBranch( catsTopToBottom, this.filterModel.categories );
    }

    // load entirely new set of projects
    this.loadFilteredProjects( true );
  }

  public loadFilteredProjects( filterHasChanged?: boolean, loadbefore: boolean = false ): void {
    if(!this.isLoadingMore) {

      this.isLoadingMore = true;
      // get projects from API
      if ( filterHasChanged === true && loadbefore === false ) {
        this.filteredProjekteCount = 0;
        this.filterModel.page = 1;
        this.filteredProjekte = [];
      }
      else if (loadbefore === true) {
        this.filteredProjekte = [];
      }


      if(loadbefore !== true) {
        NgXCookies.setCookie(
          this.COOKIE_FILTER_NAME,
          JSON.stringify( this.filterModel ),
          1, 'hours'
        );
      }

      this.filterModel.loadbefore = loadbefore;

      // console.log( JSON.stringify( this.filterModel, undefined, ' ' ) );

      this.API.post<IFilterModelProjekte, IResponseFilterProjekte>(
        '/projekte/filter',
        this.filterModel
      ).subscribe(
        response => {
          if (
            response.hasOwnProperty( 'found' )
            && response.hasOwnProperty( 'entries' )
          ) {
            this.filteredProjekteCount = response.found;

            if (
              Array.isArray( response.entries ) && response.entries.length
            ) {
              // console.log( response.entries );

              // the API responds with ENTRIES_PER_PAGE + 1 elements to tell Angular
              // whether there are more pages to load
              this.hasMorePages = response.entries.length > this.ENTRIES_PER_PAGE;
              // remove all elements more than ENTRIES_PER_PAGE
              if(this.filterModel.loadbefore === true) {
                response.entries.splice( this.ENTRIES_PER_PAGE * this.filterModel.page );
              }
              else {
                response.entries.splice( this.ENTRIES_PER_PAGE );
              }

              this.filteredProjekte = this.filteredProjekte.concat( response.entries );
              ++this.filterModel.page;
            } else {
              this.hasMorePages = false;
              this.filteredProjekte = [];
            }
          } else {
            this.hasMorePages = false;
            this.filteredProjekteCount = 0;
            this.filteredProjekte = [];
          }
        },
        err => { console.error( err ); },
        () => {
          this.isLoadingMore = false;
        }
      );
    }
  }

  public getLocaleChangeUrl( targetLocale: string ): string {
    const locUrlProperty = 'url_' + targetLocale;
    if ( this.content && this.content.hasOwnProperty( locUrlProperty ) ) {
      return this.content[ locUrlProperty ];
    }
  }

  private getFilterCategoriesAsArray( categoriesObj: ICategories ): IFilterCategories {
    return Object.keys( categoriesObj ).map(
      id => {
        const c = categoriesObj[ id ] as any as IFilterCategory;
        if ( c.children ) {
          c.children = this.getFilterCategoriesAsArray( categoriesObj[ id ].children );
        } else {
          c.children = [];
        }
        return c;
      }
    );
  }
}
