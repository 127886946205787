import { IEnvironment } from './IEnvironment';

export const environment: IEnvironment = {
  production: false,
  baseHref: '/',
  domains: {
    ch: {
      homeUrl: 'https://pirminjung.binaryone.ch',
      hostname: 'pirminjung.binaryone.ch',
    },
    de: {
      homeUrl: 'https://pirminjung.binaryone.ch/de',
      hostname: 'pirminjung.binaryone.ch',
    },
    en: {
      homeUrl: 'https://pirminjung.binaryone.ch/en',
      hostname: 'pirminjung.binaryone.ch',
    }
  }
};
