import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IBoFormConfig } from '../interfaces/IBoFormConfig';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isNumberValidator } from '../validators/IsNumberValidator';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IBoFormsResponse, IBoFormsRequest } from '../interfaces/api/RequestResponse';
import { IBoFormField } from '../interfaces/fields/IBoFormField';

@Component( {
  selector: 'bo-form',
  templateUrl: './form.component.html'
} )
export class FormComponent implements OnInit, OnChanges {
  @Output() submitSuccess = new EventEmitter<IBoFormsResponse>();
  @Output() submitError = new EventEmitter<any>();

  private _config: IBoFormConfig;
  @Input() set Config( config: IBoFormConfig ) {
    if (
      this._config
      || !config
      || !config.hasOwnProperty( 'action' )
      || !config.hasOwnProperty( 'fields' )
    ) {
      console.warn( 'form config invalid or form already initialized, skipping' );
      return;
    }

    this._config = config;
    const controls: Array<IBoFormField> = [];
    const controlsConfig = {};
    this.submitText = config.submitTexts.initial;

    for ( const field of config.fields ) {
      // attach validators
      const controlValidators = [];
      if ( field.type === 'number' ) {
        controlValidators.push( isNumberValidator );
      }
      if ( field.required === true ) {
        controlValidators.push( field.type === 'checkbox' ? Validators.requiredTrue : Validators.required );
      }
      if ( typeof field.min === 'number' ) {
        controlValidators.push( Validators.min( field.min ) );
      }
      if ( typeof field.max === 'number' ) {
        controlValidators.push( Validators.max( field.max ) );
      }
      if ( typeof field.minLength === 'number' ) {
        controlValidators.push( Validators.minLength( field.minLength ) );
      }
      if ( typeof field.maxLength === 'number' ) {
        controlValidators.push( Validators.maxLength( field.maxLength ) );
      }
      if ( field.email === true ) {
        controlValidators.push( Validators.email );
      }
      if ( typeof field.regexPattern === 'string' ) {
        const regexParts = field.regexPattern.replace( /\/(.*?)\/(.*?)$/i, '$1,$2' );
        controlValidators.push(
          Validators.pattern(
            new RegExp( regexParts[ 0 ], regexParts.length > 1 ? regexParts[ 1 ] : 'gi' )
          )
        );
      }

      // push to controls
      controls.push( field );
      controlsConfig[ field.name ] = [ field.initialValue, controlValidators ];
    }

    this.form = this.fb.group( controlsConfig );
    this.formFields = controls;
  }

  public form: FormGroup;
  public formFields: Array<IBoFormField>;

  public submitText: string;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit() {
  }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'Config':
          this.Config = changes.Config.currentValue as IBoFormConfig;
      }
      break;
    }
  }

  public onSubmit() {
    if ( !this.form.valid ) {
      console.warn( 'form not yet valid' );
      return;
    }

    const postHeaders = new HttpHeaders().set( 'X-Requested-With', 'XMLHttpRequest' );

    let formData: any = Object.assign( {}, this.form.value );
    if ( this._config.id ) {
      // attach wheelform id
      formData.form_id = this._config.id;
    }
    // check POST body type
    if ( this._config.submitAsFormdata === true ) {
      formData = convertObjectToFormData( formData );
      postHeaders.set( 'enctype', 'multipart/form-data' );
    }

    console.log( 'submitting formData', formData );

    this.form.disable();
    this.submitText = this._config.submitTexts.sending || 'sending';

    this.http.post<IBoFormsRequest>(
      this._config.action,
      formData,
      {
        headers: postHeaders,
        withCredentials: true
      }
    ).subscribe(
      ( data: IBoFormsResponse ) => {
        console.log( 'success response', data );
        if ( data && data.success ) {
          this.form.disable();
          this.submitText = this._config.submitTexts.success;
          this.submitSuccess.emit( data );
        } else {
          this.form.enable();
          this.submitText = this._config.submitTexts.error || 'error';
          this.submitError.emit( data );
        }
      },
      err => {
        console.log( 'error response', err );
        this.form.enable();
        this.submitText = this._config.submitTexts.error || 'error';
        this.submitError.emit( err );
      }
    );
  }
}


function convertObjectToFormData( obj: { [ key: string ]: any } ): FormData {
  const formData = new FormData();

  for ( const key in obj ) {
    if ( !obj.hasOwnProperty( key ) ) { continue; }
    formData.append( key, obj[ key ] );
  }

  return formData;
}
