import { IBreadcrumbs } from './IBreadcrumbs';

export class Breadcrumb {
  public slug: string;
  public name: string;
  public path: string;

  public children: IBreadcrumbs = {};

  public static attachPrototypeRecursive( bc: { slug: string; name: string; path: string; children?: IBreadcrumbs } ): void {
    Object.setPrototypeOf( bc, new Breadcrumb( '', '' ) );
    for ( const s of Object.keys( bc.children ) ) {
      const c = bc.children[ s ];
      Breadcrumb.attachPrototypeRecursive( c );
    }
  }

  constructor( slug: string, name?: string, path?: string ) {
    if ( typeof slug !== 'string' || slug.length === 0 ) {
      return null;
    }

    this.slug = slug;
    this.name = typeof name === 'string' ? name : '';
    this.path = typeof path === 'string' ? path : '';
  }

  public updateAllPathsDown(): void {
    const conjPath = this.getPathForConj();
    for ( const s of Object.keys( this.children ) ) {
      const child = this.children[ s ];
      if (
        child.slug === '/'
        || child.slug === 'ch'
        || child.slug === 'de'
        || child.slug === 'en'
        || child.slug === 'home'
      ) {
        child.path = '/';
      } else {
        child.path = conjPath + child.slug;
      }
      child.updateAllPathsDown();
    }
  }

  /**
   * Pushes a new child and sets/updates the path of the child
   *
   * @param {Breadcrumb} child
   * @returns {Breadcrumb}
   * @memberof Breadcrumb
   */
  public pushChild( child: Breadcrumb ): Breadcrumb {
    if ( child && 'slug' in child && 'path' in child ) {
      this.children[ child.slug ] = child;
      if ( this.path === '/' ) {
        child.path = '/' + ( child.path === '/' ? '' : child.slug );
      } else {
        child.path = this.path + ( child.path === '/' ? '' : '/' + child.slug );
      }
    }
    return child;
  }

  public getPathForConj(): string {
    if ( this.path.length === 0 || this.path === '/' ) {
      return '/';
    } else {
      if ( this.path.charAt( this.path.length - 1 ) !== '/' ) {
        return this.path + '/';
      }
      return this.path;
    }
  }
}
