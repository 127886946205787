import { Component } from '@angular/core';
import { NavigationService } from './services/navigation.service';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { FooterService } from './services/footer.service';
import { ILocalizedComponent } from './ILocalizedComponent';
import { LocaleService } from './localization/locale.service';
import { ScrollPositionService } from './services/scroll-position/scroll-position.service';
import { LightboxService } from './services/lightbox.service';

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html'
} )
export class AppComponent {
  public activeComponent: ILocalizedComponent;

  constructor(
    private localeService: LocaleService,
    public navigationService: NavigationService,
    public footerService: FooterService,
    breadcrumbsSvc: BreadcrumbsService, // NICHT ENTFERNEN
    scrollPosSvc: ScrollPositionService,
    lightboxSvc: LightboxService
  ) {
    scrollPosSvc.init();
  }

  public onRouteActivate( component: ILocalizedComponent ) {
    window.scroll(0, 0);
    this.activeComponent = component;
    this.localeService.CurrentRouteComponent = component;

    if ( !this.navigationService.Navigation.entry ) {
      this.navigationService.loadNavigationData();
    }
    if ( !this.footerService.Footer.entry ) {
      this.footerService.loadFooterData();
    }
  }
}
