import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IJournalOverview } from 'src/rest-interfaces/journal/IJournalOverview';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {
  constructor(private API: ApiService, private http: HttpClient) { }

  public getFacebookPostIds(): Observable<string[]> {
    return this.API.get<string[]>('/socialmedia/facebook');
  }

  public getInstagramEmbedHtml(): Observable<string[]> {
    return this.API.get<string[]>('/socialmedia/instagram');
  }

  public getLinkedinPostIds(): Observable<IJournalOverview> {
    return this.API.get<IJournalOverview>('/structure/journal');
  }
}
