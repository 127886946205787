import { Component, HostListener, ViewChild, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { LocaleService } from '../localization/locale.service';
import { ILocalizedComponent } from '../ILocalizedComponent';
import { HomeComponent } from '../page-components/home/home.component';
import { JournalComponent } from '../journal/journal.component';
import { JournalItemComponent } from '../journal/journal-item/journal-item.component';
import { headerAnimations } from '../_animations/header.animations';


enum EStatesHeaderTop {
  default = 'default',
  home = 'home',
  journal = 'journal'
}

enum EAnimationStatesHeader {
  initial = 'initial',
  scrolled = 'scrolled',
  navigationVisible = 'navigationVisible'
}


@Component( {
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [ headerAnimations ]
} )
export class HeaderComponent implements OnChanges {
  @Input() activeComponent: ILocalizedComponent;


  public EStatesHeaderTop = EStatesHeaderTop;
  public headerTopState: EStatesHeaderTop = EStatesHeaderTop.home;

  public EAnimationStatesHeader = EAnimationStatesHeader;
  public headerAnimationState: EAnimationStatesHeader = EAnimationStatesHeader.initial;
  public headerAnimationStateNonNavi: EAnimationStatesHeader = EAnimationStatesHeader.initial;

  private rootEl: HTMLBodyElement;

  public isAnimating = false;

  @ViewChild( 'header' ) header: ElementRef<HTMLHeadingElement>;
  @HostListener( 'window:scroll', [ '$event' ] ) onScroll( event: Event ) {
    if ( this.headerAnimationState === EAnimationStatesHeader.navigationVisible ) {
      return;
    }
    this.changeState( this.getStateForScrollPosition() );
  }


  constructor(
    public localeSvc: LocaleService,
    public navigationService: NavigationService,
    private rend: Renderer2
  ) {
    this.rootEl = document.getElementsByTagName( 'body' )[ 0 ];
  }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'activeComponent':
          if ( changes.activeComponent.currentValue ) {
            this.activeComponent = changes.activeComponent.currentValue;
          }
          this.changeHeaderTopState();
          this.changeState( this.getStateForScrollPosition() );
          break;
      }
    }
  }

  public isWhiteLogo(): boolean {
    return this.headerTopState === EStatesHeaderTop.default && this.headerAnimationState !== EAnimationStatesHeader.navigationVisible
      || this.headerTopState === EStatesHeaderTop.home && this.headerAnimationState === EAnimationStatesHeader.scrolled;
  }

  public isGreenLogo(): boolean {
    return this.headerTopState === EStatesHeaderTop.home
      && this.headerAnimationState === EAnimationStatesHeader.initial;
  }

  public isJournalLogo(): boolean {
    return this.headerAnimationState === EAnimationStatesHeader.initial && this.headerTopState === EStatesHeaderTop.journal;
  }

  public isJournalScrolledLogo(): boolean {
    return this.headerAnimationState === EAnimationStatesHeader.scrolled && this.headerTopState === EStatesHeaderTop.journal;
  }

  // ---------------------------------------------------------------------------
  // Animations & States

  public onMenuClick() {
    // calculate new target state
    let targetState = EAnimationStatesHeader.navigationVisible;
    if (
      this.headerAnimationState === EAnimationStatesHeader.navigationVisible
    ) {
      // target: close menu
      targetState = this.getStateForScrollPosition();
    }
    this.changeState( targetState );
  }

  public onCloseNavigation(): void {
    this.changeState( this.getStateForScrollPosition() );
  }

  private getStateForScrollPosition(): EAnimationStatesHeader {
    if ( window.pageYOffset >= 50 ) {
      this.headerAnimationStateNonNavi = EAnimationStatesHeader.scrolled;
      return this.headerAnimationStateNonNavi;
    } else {
      this.headerAnimationStateNonNavi = EAnimationStatesHeader.initial;
    }
    return this.headerAnimationStateNonNavi;
  }

  private changeState( targetState: EAnimationStatesHeader ): void {
    // skip if targetState is already current state
    if ( targetState === this.headerAnimationState ) {
      return;
    }

    // switch through current state
    switch ( this.headerAnimationState ) {
      case EAnimationStatesHeader.initial:
        break;
      case EAnimationStatesHeader.scrolled:
        break;
      case EAnimationStatesHeader.navigationVisible:
        break;
    }


    // calculate scrollbar width
    let scrollbarWidth = window.innerWidth - this.rootEl.offsetWidth;
    if ( typeof scrollbarWidth !== 'number' || scrollbarWidth < 0 ) {
      scrollbarWidth = 0;
    }
    if (
      targetState === EAnimationStatesHeader.initial
      || targetState === EAnimationStatesHeader.scrolled
    ) {
      // re-enable scrolling on body
      this.rootEl.style.overflowX = 'hidden';
      this.rootEl.style.overflowY = 'scroll';
      // move navIcon back to its original position, appearing body scrollbar
      this.header.nativeElement.style.right = '0';
      // Enable Pointerevents on Body
      setTimeout( () => {
        this.rootEl.style.pointerEvents = '';
      }, 1600 );
    } else {
      // disable scrolling on body
      this.rootEl.style.overflowX = 'hidden';
      this.rootEl.style.overflowY = 'hidden';
      // move navIcon to the left because of disappearing body scrollbar
      this.header.nativeElement.style.right = scrollbarWidth.toString() + 'px';
      // disable PointerEvents on body
      this.rootEl.style.pointerEvents = 'none';
    }

    // set animating state
    this.isAnimating = true;
    setTimeout( () => {
      this.isAnimating = false;
    }, 1600 );

    // set new state
    this.headerAnimationState = targetState;
  }

  // END Animations & States
  // ---------------------------------------------------------------------------


  private changeHeaderTopState(): void {
    if ( this.activeComponent instanceof HomeComponent ) {
      this.headerTopState = EStatesHeaderTop.home;
    } else if (
      this.activeComponent instanceof JournalComponent
      || this.activeComponent instanceof JournalItemComponent
    ) {
      this.headerTopState = EStatesHeaderTop.journal;
    } else if ( typeof this.activeComponent !== 'undefined' ) {
      this.headerTopState = EStatesHeaderTop.default;
    }
    this.rootEl.style.overflowY = 'scroll';
    this.changeState( EAnimationStatesHeader.initial );
  }
}
