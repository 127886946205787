import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ROUTES_CH } from './localization/ROUTES_CH';
import { ROUTES_EN } from './localization/ROUTES_EN';

const routes = [
  ...ROUTES_CH,
  // ...ROUTES_EN,
  // default 404 redirect
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes, { enableTracing: false } ) ],
  exports: [ RouterModule ]
} )
export class AppRoutingModule { }
