import { Component } from '@angular/core';
import { generalAnimations } from '../_animations/general.animations';
import { Meta, Title } from '@angular/platform-browser';
import { IEntryJournalGrid, IEntryJournal } from 'src/rest-interfaces/IEntryJournal';
import { ScrollService } from '../services/scroll.service';
import { JournalService } from '../services/journal.service';
import { IJournalCategory } from 'src/rest-interfaces/journal/IJournalCategory';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '../localization/locale.service';
import { EntryService } from '../services/entry.service';
import { ScrollPositionService } from '../services/scroll-position/scroll-position.service';
import { NgXCookies } from 'src/app/services/ngx-cookies';

@Component( {
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  animations: [ generalAnimations ]
} )
export class JournalComponent {
  private readonly COOKIE_FILTER_NAME = '__jrnlfltr';

  public stories: Array<IEntryJournalGrid>;
  public categories: Array<IJournalCategory> = [];
  public hasMoreStories = false;
  public isLoadingMore = false;

  private currentStoriesPage = 0;
  public selectedCategory: IJournalCategory;
  public searchterm = '';
  public term: string;
  public loadBefore: boolean = false;

  constructor(
    private entrySvc: EntryService,
    public journalSvc: JournalService,
    private localeSvc: LocaleService,
    private scrollService: ScrollService,
    private route: ActivatedRoute,
    private title: Title,
    private scrollPosSvc: ScrollPositionService
  ) {
    this.route.url.subscribe(
      () => {
        this.entrySvc.uri(
          this.localeSvc.RouterUri
        ).subscribe(
          t => {
            this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );

            scrollPosSvc.popScrollPos();
          },
        );
      }
    );
    this.journalSvc.categories().subscribe(
      categories => { this.categories = categories; }
    );

    if ( NgXCookies.exists( this.COOKIE_FILTER_NAME ) ) {
      let cookieData = JSON.parse(NgXCookies.getCookie( this.COOKIE_FILTER_NAME ));
      this.selectedCategory = cookieData.category;
      this.term = cookieData.term;
      this.currentStoriesPage = cookieData.page;
      
      this.loadBefore = true;

      this.currentStoriesPage--;
      this.loadMoreStories();
    }
    else {
      this.getStories( null, '' );
    }

  }

  public getStories( category?: IJournalCategory, term?: string ): void {
    if (
      typeof category === 'object'
      && category !== this.selectedCategory
      || typeof term === 'string'
      && term !== this.term
    ) {
      // remove all previously loaded stories
      // because we are getting new ones by category or term
      this.stories = [];
      this.currentStoriesPage = 0;
      this.hasMoreStories = false;

      if (
        typeof category === 'object'
        && category !== this.selectedCategory
      ) {
        this.selectedCategory = category;
      }

      if (
        typeof term === 'string'
        && term !== this.term
      ) {
        this.term = term;
      }

      // console.log( 'loading stories', this.selectedCategory, 'term="' + this.term + '"' );
      this.loadMoreStories();
    }
  }

  public loadMoreStories(): void {
    if ( this.isLoadingMore ) { return; }

    ++this.currentStoriesPage;

    this.isLoadingMore = true;
    this.journalSvc.feed(
      this.currentStoriesPage,
      typeof this.selectedCategory === 'object'
        && this.selectedCategory !== null
        && this.selectedCategory.hasOwnProperty( 'id' )
        ?
        Number.parseInt( this.selectedCategory.id, 10 )
        :
        0,
      this.term,
      this.loadBefore
    ).subscribe(
      entries => {
        if( this.loadBefore ) {
          this.hasMoreStories = ((this.currentStoriesPage * 8) + 1) < entries.length;
          JournalComponent.updateHdHIconFlags( entries );
          this.stories = entries.slice( 0, (this.currentStoriesPage * 8) + 1 );

          this.isLoadingMore = false;
        }
        else {
          this.hasMoreStories = this.currentStoriesPage === 1
          && entries.length > 8
          ?
          true
          :
          entries.length > 8;
          JournalComponent.updateHdHIconFlags( entries );
          this.stories = this.stories.concat(
            this.currentStoriesPage === 1 ? entries : entries.slice( 0, 8 )
          );

          this.isLoadingMore = false;
        }

      },
      () => {
        this.isLoadingMore = false;
      }
    );
  }

  public loadMoreStoriesClick(): void {
    let cookieData = {
      term: this.term,
      category: this.selectedCategory,
      page: this.currentStoriesPage + 1
    };

    NgXCookies.setCookie(
      this.COOKIE_FILTER_NAME,
      JSON.stringify( cookieData ),
      1, 'hours'
    );

    this.loadBefore = false;
    this.loadMoreStories();
  }

  public onCatSelected( c?: IJournalCategory ) {
    if ( typeof c !== 'object' ) {
      c = null;
    }

    let cookieData = {
      term: this.term,
      category: this.selectedCategory,
      page: this.currentStoriesPage
    };

    NgXCookies.setCookie(
      this.COOKIE_FILTER_NAME,
      JSON.stringify( cookieData ),
      1, 'hours'
    );

    this.loadBefore = false;
    this.getStories( c );
  }

  public onTermChanged( e: Event ): void {
    e.preventDefault();

    let cookieData = {
      term: this.term,
      category: this.selectedCategory,
      page: this.currentStoriesPage
    };

    NgXCookies.setCookie(
      this.COOKIE_FILTER_NAME,
      JSON.stringify( cookieData ),
      1, 'hours'
    );

    this.loadBefore = false;
    this.getStories( this.selectedCategory, this.searchterm );
  }

  public onScrollDown( event: Event ) {
    this.scrollService.triggerScrollToOffset( 'scrollDestination', -100 );
  }

  // ------------------------------
  // grid helpers
  public storyIsMain( i: number ): boolean {
    return i === 0;
  }
  public storyIsHalf( i: number ): boolean {
    const pos = i % 8;
    return i > 0 && ( pos === 1 || pos === 2 );
  }
  public storyIsThird( i: number ): boolean {
    const pos = i % 8;
    return i > 0 && ( pos === 0 || pos > 2 );
  }

  public static updateHdHIconFlags( entries: Array<IEntryJournalGrid> ): void {
    for ( const entry of entries ) {
      if ( JournalComponent.hasHdhCategory( entry ) ) {
        entry.showHdhIcon = true;
      } else {
        entry.showHdhIcon = false;
      }
    }
  }

  public static hasHdhCategory( entry: IEntryJournalGrid ): boolean {
    return entry
      && entry.kategorie
      && entry.kategorie.hasOwnProperty( 'haus-des-holzes' );
  }
}
