import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Breadcrumb } from '../breadcrumbs/Breadcrumb';
import { filter } from 'rxjs/operators';
import { LocaleService } from '../localization/locale.service';
import { UrlHelper } from '../helpers/url.helper';
import { ELocale } from '../localization/ELocale';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';


@Injectable()
export class LoginService {

  public isLoggedIn: boolean
  public error: string;

  constructor(
    private API: ApiService,
    private router: Router,
  ) {
    this.API.get(
      '/session/user'
    ).subscribe(
      (response: any) => {
        this.isLoggedIn = true;
        this.error = '';
      },
      err => { 
        console.error( err );
        this.isLoggedIn = false;
      },
      () => {

      }
    );
  }

  public setLoggedIn(loggedIn: boolean) {
    this.isLoggedIn = loggedIn;
  }

  logout() {
    this.API.get(
      '/session/logout'
    ).subscribe(
      (response: any) => {
        this.isLoggedIn = false;
        this.router.navigate(['/projekte']);
      },
      err => { 
        console.error( err );
      },
      () => {

      }
    );
  }

  login(loginValues: any) {
    this.API.post(
      '/users/login',
      loginValues
    ).subscribe(
      response => {
        console.log('login', response);

        if(response.success) {
          this.isLoggedIn = true;
          this.router.navigate(['/projekte']);
        }
        else {
          this.error = 'Falscher Username oder falsches Passwort.'
        }
      },
      err => { 
        console.error( err );
        this.error = 'Falscher Username oder falsches Passwort.'
      },
      () => {

      }
    );
  }

}
