import { Injectable } from '@angular/core';
import { IFooter } from 'src/rest-interfaces/IFooter';
import { ApiService } from '../api/api.service';
import { LocaleService } from '../localization/locale.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { INavigationCurrent, INavLinkEintrag } from '../../rest-interfaces/INavigation';
import { UrlHelper } from '../helpers/url.helper';

@Injectable()
export class FooterService {
  private loadingFooter = false;
  private _footer: { entry: IFooter; } = {
    entry: null
  };
  public get Footer(): { entry: IFooter; } {
    return this._footer;
  }

  private _currentNavEntry: INavLinkEintrag & INavigationCurrent;
  public get CurrentNavEntry(): INavLinkEintrag & INavigationCurrent {
    return this._currentNavEntry;
  }

  private routeSubscription: Subscription;

  constructor(
    private API: ApiService,
    private localeSvc: LocaleService,
    private router: Router
  ) { }

  public loadFooterData(): void {
    if ( this.loadingFooter ) {
      return;
    }
    // console.log( 'loadFooterData()' );
    this.loadingFooter = true;
    // zip with router events not possible, because <app-footer/> is outside of <router-outlet/>
    this.API.get<IFooter>( '/footer' ).subscribe(
      footer => {
        this._footer.entry = footer;

        if ( !this.routeSubscription ) {
          this.routeSubscription = this.router.events.pipe(
            filter( e => e instanceof NavigationEnd )
          ).subscribe(
            () => {
              this.updateNavigationCurrentPage();
            }
          );
        }
        this.updateNavigationCurrentPage();

        this.loadingFooter = false;
      }
    );
  }

  private updateNavigationCurrentPage() {
    if ( !this._footer || !this._footer.entry ) {
      return;
    }
    const footer = this._footer.entry;

    // remove leading and trailing '/'
    const currentLocationPath = this.localeSvc.getCurrentLocationPath().replace( /^\/|\/$/g, '' );
    const currentLocationPathSegments = currentLocationPath.split( '/' );

    let highestSegmMatchCount = 0;
    let currentBestMatch: INavigationCurrent = null;
    const navigationKeys = [ 'navigationMenuFlach', 'navigationUntermenu' ];
    for ( const navKey of navigationKeys ) {
      if ( footer.hasOwnProperty( navKey ) ) {
        for ( const n of ( footer[ navKey ] as Array<INavLinkEintrag & INavigationCurrent> ) ) {
          n.isCurrent = false;
          if (
            !Array.isArray( n.eintrag )
            || n.eintrag.length === 0
          ) { continue; }
          const segments = n.eintrag[ 0 ].url
            .replace( '__home__', '' )
            .replace( new RegExp( '^' + this.localeSvc.LocaleHomeUrl ), '' )
            .replace( /^\/|\/$/g, '' )
            .split( '/' );
          // console.log( currentLocationPathSegments, segments, n.eintrag[ 0 ].url );
          const segMatchCount = UrlHelper.getMatchingSegmentsCount( segments, currentLocationPathSegments );
          if ( segMatchCount > highestSegmMatchCount ) {
            highestSegmMatchCount = segMatchCount;
            currentBestMatch = n;
          }
        }
      }
    }

    if ( currentBestMatch ) {
      currentBestMatch.isCurrent = true;
      this._currentNavEntry = currentBestMatch as INavLinkEintrag & INavigationCurrent;
    }
  }
}
