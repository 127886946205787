import { Injectable } from '@angular/core';
import { IAddThis } from './IAddThis';
import { BehaviorSubject } from 'rxjs';


// ---------------------------------------
// AddThis Public ID

const PUBID = 'ra-5c45bf7cf4afef92';

// ---------------------------------------


// helper variable for window.addthis namespace
const wnd = window as typeof window & { __sharethis__: IAddThis; };

@Injectable()
export class AddThisService {
  private readonly ADDTHIS_JS_URL = 'https://platform-api.sharethis.com/js/sharethis.js#property=646b304258d85b001927ba3a&product=sop';
  private scriptEl: HTMLScriptElement;

  private _addthis: IAddThis;
  public get AddThis(): IAddThis {
    return this._addthis;
  }

  private $addThisReady = new BehaviorSubject( false );

  constructor() {
    this.loadAddThisJS();
  }

  public refresh() {
    this.AddThis.initialize();
  }

  private loadAddThisJS() {
    if ( wnd.__sharethis__ ) {
      // console.warn( 'addthis already initialized' );
    } else if ( !( this.scriptEl instanceof HTMLScriptElement ) ) {
      this.scriptEl = document.createElement( 'script' );
      this.scriptEl.setAttribute( 'src', this.ADDTHIS_JS_URL );
      this.scriptEl.setAttribute( 'defer', '' );

      this.scriptEl.onload = this.addThisLoaded.bind( this );

      const body = document.getElementsByTagName( 'body' )[ 0 ];
      body.appendChild( this.scriptEl );
    }
  }

  private addThisLoaded() {
    this._addthis = wnd.__sharethis__;


    setTimeout(() => {
      this.AddThis.initialize();
    }, 100);
  }
}
