import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EntryService } from 'src/app/services/entry.service';
import { IEntryAngeboteItem } from 'src/rest-interfaces/angebote/IEntryAngebote';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '../../localization/locale.service';
import { ILocalizedComponent } from 'src/app/ILocalizedComponent';
import { generalAnimations } from 'src/app/_animations/general.animations';
import { SliderConfigService } from 'src/app/services/slider-config.service';
import { LightboxService } from 'src/app/services/lightbox.service';

@Component( {
  selector: 'app-angebote-item',
  templateUrl: './angebote-item.component.html',
  animations: [ generalAnimations ]
} )
export class AngeboteItemComponent implements ILocalizedComponent {
  public content: IEntryAngeboteItem;
  public currentSlide: number = 0;

  constructor(
    private entrySvc: EntryService,
    private localeSvc: LocaleService,
    private route: ActivatedRoute,
    private title: Title,
    public SliderConfigSvc: SliderConfigService,
    public lightboxSvc: LightboxService,
  ) {
    this.route.url.subscribe(
      () => {
        this.entrySvc.uri<IEntryAngeboteItem>(
          this.localeSvc.RouterUri
        ).subscribe(
          t => {
            this.content = t;
            this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );
          },
          () => { this.localeSvc.navigateToHome(); }
        );
      }
    );
  }

  public getLocaleChangeUrl( targetLocale: string ): string {
    const locUrlProperty = 'url_' + targetLocale;
    if ( this.content && this.content.hasOwnProperty( locUrlProperty ) ) {
      return this.content[ locUrlProperty ];
    }
  }

  public afterChange( event: Event ) {
    this.currentSlide = ( event as any ).currentSlide;
  }

  public showInLightBox(bilder) {
    this.lightboxSvc.setLightboxContent(bilder, this.currentSlide);
  }
}
