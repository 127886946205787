import { Pipe, PipeTransform, HostListener, HostBinding } from '@angular/core';

@Pipe( {
  name: 'shorten',
  pure: true
} )
export class ShortenPipe implements PipeTransform {
  public static readonly DEFAULT_LENGTH = 120;

  private limit = ShortenPipe.DEFAULT_LENGTH;

  public transform( rawText: string, limit?: number ): string {
    if ( typeof limit === 'number' && limit >= 0 ) {
      this.limit = limit;
    }

    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const doc = parser.parseFromString( rawText, 'text/html' );

    if ( rawText.length > this.limit ) {
      this.shortenNodes( doc.body, this.limit );
    }
    return serializer.serializeToString( doc.documentElement );
  }

  private shortenNodes( parent: Node, limit: number, currLimit?: number ): number {
    if ( typeof currLimit !== 'number' || currLimit < 0 ) {
      currLimit = 0;
    }
    for ( let i = 0; i < parent.childNodes.length; ++i ) {
      const node = parent.childNodes.item( i );
      if ( currLimit < limit ) {
        currLimit = this.shortenNode( node, limit, currLimit );
        // console.log( 'shortenNodes:' + currLimit.toString() + `(${limit})` );
      } else {
        // console.log( `limit reached ${currLimit}, removing all following nodes...` );
        for ( let rmI = parent.childNodes.length - 1; rmI >= i + 1; --rmI ) {
          parent.removeChild( parent.childNodes.item( rmI ) );
        }
        break;
      }
    }
    return currLimit;
  }

  private shortenNode( node: Node, limit: number, currLimit: number ): number {
    // console.log( 'shortenNode "' + node.nodeName + '" before:' + currLimit.toString() + `(${limit})` );
    if ( currLimit >= limit ) {
      return currLimit;
    }
    switch ( node.nodeType ) {
      case Node.ELEMENT_NODE:
        currLimit = this.shortenNodes( node, limit, currLimit );
        break;
      case Node.TEXT_NODE:
        const txtLen = node.nodeValue.length;
        // console.log( 'txtLen:' + txtLen.toString() );
        if ( currLimit + txtLen >= limit ) {
          // console.log( `shortening to ${limit - currLimit - 1} ...` );
          node.nodeValue = node.nodeValue.substr( 0, limit - currLimit - 1 ) + '…';
        }
        currLimit += node.nodeValue.length;
    }
    // console.log( 'shortenNode "' + node.nodeName + '" after:' + currLimit.toString() + `(${limit})` );
    return currLimit;
  }
}