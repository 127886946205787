import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { navAnimations } from '../_animations/navigation.animations';
import { LocaleService } from '../localization/locale.service';
import { Router } from '@angular/router';
import { SucheService } from 'src/app/services/suche.service';

enum EAnimationStatesNav {
  hidden = 'hidden',
  show = 'show',
}
@Component( {
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  animations: [ navAnimations ]
} )
export class NavigationComponent implements OnChanges {
  @Input() navigationVisible: boolean;
  @Output() closeNavigation = new EventEmitter<void>();

  public navAnimState: EAnimationStatesNav = EAnimationStatesNav.hidden;
  public searchTerm: string = "";

  constructor(
    public navigationService: NavigationService,
    public localeSvc: LocaleService,
    private router: Router,
    public sucheSvc: SucheService
  ) { }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'navigationVisible':
          this.navigationVisible = Boolean( changes.navigationVisible.currentValue );
          this.navAnimState = this.navigationVisible ? EAnimationStatesNav.show : EAnimationStatesNav.hidden;
          break;
      }
    }
  }

  public onLinkClicked( isCurrentPage?: boolean ): void {
    if ( typeof isCurrentPage !== 'boolean' || !isCurrentPage ) {
      window.scroll( 0, 0 );
    }
    this.closeNavigation.emit();
  }

  public onSearch(event) {
    event.preventDefault();

    this.sucheSvc.suchen();

    this.router.navigate( [ '/suche' ] );
    this.closeNavigation.emit();
  }
}
