import { Component, Input, ElementRef } from '@angular/core';
import { IEntryPlantATree } from 'src/rest-interfaces/IEntryPlantATree';

@Component( {
  selector: 'app-plant-a-tree',
  templateUrl: './plant-a-tree.component.html'
} )

export class PlantATreeComponent {
  @Input() content: IEntryPlantATree;

  private readonly URL = 'https://widgets.plant-for-the-planet.org/build/tree-map.js';

  constructor(
    private el: ElementRef
  ) {
    this.initPlantATreeWidget();
  }

  initPlantATreeWidget() {
    const scriptEl = document.createElement( 'script' );
    scriptEl.setAttribute( 'id', 'treeMapScript' );
    scriptEl.setAttribute( 'src', this.URL );
    this.el.nativeElement.appendChild( scriptEl );
  }
}
