import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Breadcrumb } from '../breadcrumbs/Breadcrumb';
import { filter } from 'rxjs/operators';
import { LocaleService } from '../localization/locale.service';
import { UrlHelper } from '../helpers/url.helper';
import { ELocale } from '../localization/ELocale';

@Injectable()
export class BreadcrumbsService {
  private _breadcrumbsRoot: Breadcrumb;

  public get Breadcrumbs(): Array<Breadcrumb> {
    const urlPath = this.router.url;
    const locale = UrlHelper.currentLocaleFromDomain();
    const segments = urlPath.substr( 1 ).split( '/' );

    const langRoot = this._breadcrumbsRoot.children[ locale ];

    const bc: Array<Breadcrumb> = [ langRoot.children.home ];
    let parent: Breadcrumb = langRoot;
    for ( const s of segments ) {
      const bSlug = Object.keys( parent.children ).find( el => s === el );
      if ( bSlug ) {
        const b = parent.children[ bSlug ];
        bc.push( b );
        parent = b;
      } else {
        // console.log( 'Breadcrumbs empty!', parent, bSlug, segments );
        return [];
      }
    }
    // console.log( 'Breadcrumbs', bc );
    return bc;
  }

  public get BreadcrumbsJournal(): Array<Breadcrumb> {
    const urlPath = this.router.url;
    const locale = UrlHelper.currentLocaleFromDomain();
    const segments = urlPath.substr( 9 ).split( '/' );
    // console.log( 'bc journal segments', segments );

    const langRoot = this._breadcrumbsRoot.children[ locale ];

    const bc: Array<Breadcrumb> = [ langRoot.children.journal ];
    let parent: Breadcrumb = langRoot.children.journal;
    for ( const s of segments ) {
      const bSlug = Object.keys( parent.children ).find( el => s === el );
      if ( bSlug ) {
        const b = parent.children[ bSlug ];
        bc.push( b );
        parent = b;
      } else {
        // console.log( 'Breadcrumbs empty!', parent, bSlug, segments );
        return [];
      }
    }
    return bc;
  }

  constructor(
    private router: Router
  ) {
    let bc: Breadcrumb;
    // load breadcrumbs from localStorage
    if ( localStorage ) {
      // bc = JSON.parse( localStorage.getItem( 'bc' ) ) as Breadcrumb;
    }
    if (
      bc
      && bc.hasOwnProperty( 'children' )
      && bc.children.hasOwnProperty( ELocale.ch )
    ) {
      Breadcrumb.attachPrototypeRecursive( bc )
      this._breadcrumbsRoot = bc;
      this._breadcrumbsRoot.updateAllPathsDown();
    } else {
      this.initializeBreadcrumbs();
    }
    // console.log( JSON.stringify( this._breadcrumbsRoot ) );

    // subscribe to URL changes and update breadcrumbs structure
    this.router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).subscribe(
      ( e: NavigationEnd ) => {
        const locale = UrlHelper.currentLocaleFromDomain();
        const segments = e.urlAfterRedirects.substr( 1 ).split( '/' );
        // console.log( 'constructor: segments', segments )
        this.parseSegments( locale, segments );
      }
    );
  }

  public ApiTapper( data ) {
    if (
      data
      && data.hasOwnProperty( 'siteId' )
      && data.hasOwnProperty( 'title' )
      && data.hasOwnProperty( 'uri' )
    ) {
      let uri = data.uri;
      if (
        !uri.length
        || uri === '__home__'
      ) {
        uri = 'home';
      }

      const locale = LocaleService.getLocaleFromSiteId( data.siteId );
      const segments = uri.split( '/' );
      const title = this.getTitleForBreadcrumb( data );
      // console.log( 'ApiTapper', locale, segments, title, data );
      this.parseSegments( locale, segments, title );
      // console.log( JSON.stringify( this._breadcrumbsRoot ) );
    }
  }

  private parseSegments( locale: string, segments: Array<string>, pageTitle?: string ) {
    if ( !Array.isArray( segments ) || segments.length === 0 ) { return; }

    // console.log( 'parseSegments', locale, segments, pageTitle );

    // remove locale segment (in API calls)
    if ( segments[ 0 ] === locale ) {
      // console.log( 'locale stripped from segments' );
      segments.splice( 0, 1 );
    }

    if ( typeof pageTitle !== 'string' ) {
      pageTitle = '';
    }

    let updated = false;
    let parentBreadcrumb: Breadcrumb = this._breadcrumbsRoot.children[ locale ];
    for ( let i = 0; i < segments.length; ++i ) {
      let s = segments[ i ];
      if ( s === '' ) { s = 'home'; }
      if ( !parentBreadcrumb.children.hasOwnProperty( s ) ) {
        const bc = new Breadcrumb( s, pageTitle );
        // add new bc to parent as child
        parentBreadcrumb.pushChild( bc );
        // move parent reference to new bc for next loop
        parentBreadcrumb = bc;
        updated = true;
      } else {
        if (
          i === segments.length - 1
          && pageTitle.length
          && !parentBreadcrumb.children[ s ].name.length
        ) {
          parentBreadcrumb.children[ s ].name = pageTitle;
          updated = true;
        }
        parentBreadcrumb = parentBreadcrumb.children[ s ];
      }
    }

    if ( updated ) {
      this.updateLocalStorage();
    }
  }

  private getTitleForBreadcrumb( entry: any ): string {
    if (
      entry.hasOwnProperty( 'projektTitel' )
      && typeof entry.projektTitel === 'string'
      && entry.projektTitel.length
    ) {
      return entry.projektTitel;
    } else if ( entry.title === 'Journal Übersicht' ) {
      return 'Journal';
    }
    return entry.title;
  }

  private updateLocalStorage(): void {
    localStorage.setItem( 'bc', JSON.stringify( this._breadcrumbsRoot ) );
    // console.log( '_crumbs', this._breadcrumbsRoot.children, JSON.stringify( this._breadcrumbsRoot.children, null, '    ' ) );
  }

  private initializeBreadcrumbs() {
    // add root node
    this._breadcrumbsRoot = new Breadcrumb( '/', 'ROOT', '/' );

    // add locale nodes
    const ch = new Breadcrumb( ELocale.ch, 'CH', '/' );
    const de = new Breadcrumb( ELocale.de, 'DE', '/' );
    const en = new Breadcrumb( ELocale.en, 'EN', '/' );

    this._breadcrumbsRoot.pushChild( ch );
    this._breadcrumbsRoot.pushChild( de );
    this._breadcrumbsRoot.pushChild( en );

    ch.pushChild( new Breadcrumb( 'home', 'Home', '/' ) );
    de.pushChild( new Breadcrumb( 'home', 'Home', '/' ) );
    en.pushChild( new Breadcrumb( 'home', 'Home', '/' ) );

    ch.pushChild( new Breadcrumb( 'ueber-uns', 'Über Uns' ) );
    de.pushChild( new Breadcrumb( 'ueber-uns', 'Über Uns' ) );
    en.pushChild( new Breadcrumb( 'about', 'About' ) );
    ch.children[ 'ueber-uns' ].pushChild( new Breadcrumb( 'team', 'Team' ) );
    de.children[ 'ueber-uns' ].pushChild( new Breadcrumb( 'team', 'Team' ) );
    en.children[ 'about' ].pushChild( new Breadcrumb( 'team', 'Team' ) );
    ch.children[ 'ueber-uns' ].pushChild( new Breadcrumb( 'engagement', 'Engagement' ) );
    de.children[ 'ueber-uns' ].pushChild( new Breadcrumb( 'engagement', 'Engagement' ) );
    en.children[ 'about' ].pushChild( new Breadcrumb( 'engagement', 'Engagement' ) );

    ch.pushChild( new Breadcrumb( 'angebote', 'Angebote' ) );
    de.pushChild( new Breadcrumb( 'angebote', 'Angebote' ) );
    en.pushChild( new Breadcrumb( 'offers', 'Offers' ) );

    ch.pushChild( new Breadcrumb( 'projekte', 'Projekte' ) );
    de.pushChild( new Breadcrumb( 'projekte', 'Projekte' ) );
    en.pushChild( new Breadcrumb( 'projects', 'Projects' ) );

    ch.pushChild( new Breadcrumb( 'journal', 'Journal' ) );
    de.pushChild( new Breadcrumb( 'journal', 'Journal' ) );
    en.pushChild( new Breadcrumb( 'journal', 'Journal' ) );

    ch.pushChild( new Breadcrumb( 'kontakt', 'Kontakt' ) );
    de.pushChild( new Breadcrumb( 'kontakt', 'Kontakt' ) );
    en.pushChild( new Breadcrumb( 'contact', 'Contact' ) );

    ch.pushChild( new Breadcrumb( 'agb', 'AGB' ) );
    de.pushChild( new Breadcrumb( 'agb', 'AGB' ) );
    en.pushChild( new Breadcrumb( 'terms', 'Terms' ) );

    ch.pushChild( new Breadcrumb( 'datenschutz', 'Datenschutz' ) );
    de.pushChild( new Breadcrumb( 'datenschutz', 'Datenschutz' ) );
    en.pushChild( new Breadcrumb( 'datenschutz', 'Privacy Policy' ) );

    ch.pushChild( new Breadcrumb( 'impressum', 'Impressum' ) );
    de.pushChild( new Breadcrumb( 'impressum', 'Impressum' ) );
    en.pushChild( new Breadcrumb( 'impressum', 'Imprint' ) );

    this._breadcrumbsRoot.updateAllPathsDown();
    this.updateLocalStorage();
  }
}
