import { Component, AfterViewInit } from '@angular/core';
import { AddThisService } from './addthis.service';

@Component( {
  selector: 'addthis',
  template: '<div class="sharethis-inline-share-buttons"></div>'
} )
export class AddThisComponent implements AfterViewInit {
  constructor(
    private addThisSvc: AddThisService
  ) { }

  ngAfterViewInit() {
    this.addThisSvc.refresh();
  }
}
