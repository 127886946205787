
// Delay im footer bis ScrollMagic Animation Startet.
export const footerDelay = .1;

// Duration der ScrollMagic Animation
export const scrollMagicDuration = 0.5;

// Duration der ScrollMagic Animationen im Header
export const headerDuration = .1;

// Position des TriggerHooks bei ScrollMagic
export const hookPosition = 0.75;

export const fullHookPos = 0.75;

export const mobileHookPos = 0.85;
