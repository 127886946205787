import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IMcbjBildBlock } from 'src/rest-interfaces/journal/IModularContentJournal.field';
import { ModularContentHelper } from 'src/app/helpers/modular-content.helper';
import { SliderConfigService } from 'src/app/services/slider-config.service';
import { LightboxService } from 'src/app/services/lightbox.service';

@Component( {
  selector: 'app-journal-bild-block',
  templateUrl: './journal-bild-block.component.html'
} )
export class JournalBildBlockComponent implements OnChanges {
  @Input( 'content' ) content: IMcbjBildBlock;
  public blockOrientationColClasses = ModularContentHelper.COL_IMAGE_CLASSES_LEFT;
  public currentSlide = 0;

  constructor(
    public SliderConfigSvc: SliderConfigService,
    public lightboxSvc: LightboxService
  ) { }

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'content':
          if ( changes.content.currentValue ) {
            const content = changes.content.currentValue as IMcbjBildBlock;
            this.blockOrientationColClasses = ModularContentHelper.ImageBlockOrientationToColClasses( content.position );

            this.setSliderConfig( content );
            this.content = content;
          } else {
            this.blockOrientationColClasses = ModularContentHelper.COL_IMAGE_CLASSES_LEFT;
          }
      }
    }
  }

  public afterChange( event: Event ) {
    this.currentSlide = ( event as any ).currentSlide;
  }

  public showInLightBox(bilder) {
    this.lightboxSvc.setLightboxContent(bilder, this.currentSlide);
  }

  private setSliderConfig( blk: IMcbjBildBlock ) {
    blk.sliderConfig = {
      arrows: false,
      autoplay: false,
      dots: false,
      draggable: true,
      infinite: true,
      rows: 1
    };
  }
}
