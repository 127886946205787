import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, zip, map, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { Router, NavigationEnd } from '@angular/router';
import { IEntryDefaultPage } from 'src/rest-interfaces/IEntryDefautlPage';
import { BreadcrumbsService } from './breadcrumbs.service';

@Injectable()
export class EntryService {
  constructor(
    private API: ApiService,
    private router: Router,
    private breadcrumbsSvc: BreadcrumbsService
  ) { }

  public entry<T = IEntryDefaultPage>( slug: string ): Observable<T> {
    return this.router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).pipe(
      zip( this.API.get<T>( '/single-page/' + slug ) )
    ).pipe(
      map( d => d[ 1 ] )
    ).pipe(
      tap( this.breadcrumbsSvc.ApiTapper.bind( this.breadcrumbsSvc ) )
    ).pipe(
      tap( () => { window.scroll( 0, 0 ); } )
    );
  }

  public entriesFlat<T = IEntryDefaultPage>( section: string ): Observable<Array<T>> {
    return this.router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).pipe(
      zip( this.API.get<Array<T>>( '/multi-pages/' + section ) )
    ).pipe(
      map( d => d[ 1 ] )
    ).pipe(
      tap( this.breadcrumbsSvc.ApiTapper.bind( this.breadcrumbsSvc ) )
    ).pipe(
      tap( () => { window.scroll( 0, 0 ); } )
    );
  }

  public entriesMoreFeed<T = IEntryDefaultPage>( section: string, pagenum?: number ): Observable<Array<T>> {
    if (
      typeof pagenum !== 'number'
      || pagenum <= 0
    ) {
      pagenum = 1;
    }
    return this.API.get<Array<T>>( '/multi-pages/' + section + '/' + pagenum );
  }

  public uri<T = IEntryDefaultPage>( structureEntryUri: string ): Observable<T> {
    return this.router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).pipe(
      zip( this.API.get<T>( '/structure/' + structureEntryUri ) )
    ).pipe(
      map( d => d[ 1 ] )
    ).pipe(
      tap( this.breadcrumbsSvc.ApiTapper.bind( this.breadcrumbsSvc ) )
    ).pipe(
      tap( () => { window.scroll( 0, 0 ); } )
    );
  }
  public customControllerUri<T = IEntryDefaultPage>( teamUri: string ): Observable<T> {
    return this.router.events.pipe(
      filter( e => e instanceof NavigationEnd )
    ).pipe(
      zip( this.API.get<T>( teamUri ) )
    ).pipe(
      map( d => d[ 1 ] )
    ).pipe(
      tap( this.breadcrumbsSvc.ApiTapper.bind( this.breadcrumbsSvc ) )
    ).pipe(
      tap( () => { window.scroll( 0, 0 ); } )
    );
  }
}
