import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { API_BASE_URL } from '../api/api.config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ILocalizedComponent } from '../ILocalizedComponent';
import { ELocale } from './ELocale';
import { UrlHelper } from '../helpers/url.helper';
import { environment } from 'src/environments/environment';

@Injectable()
export class LocaleService {
  private readonly LOCALE_COOKIE_NAME = '__locale';
  private _locale: ELocale;
  public get Locale(): ELocale {
    return this._locale;
  }
  /**
   * Localized base path for API requests.
   * Default locale 'ch': '/'
   * Other locales: 'en/', 'de/'
   *
   * @readonly
   * @type {string}
   * @memberof LocaleService
   */
  public get ApiLocaleBasePath(): string {
    switch ( this._locale ) {
      case ELocale.ch:
        return '/';
      default:
        return this._locale + '/';
    }
  }
  /**
   * Returns the locale path with / prefix if non-empty.
   * Default locale 'ch': ''
   * Other locales: '/en', '/de'
   *
   * @readonly
   * @type {string}
   * @memberof LocaleService
   */
  public get LocalePathPre(): string {
    // if ( this._locale ) {
    //   return '/' + this._locale;
    // }
    return '';
  }
  /**
   * Returns the locale home URL.
   * For multi-domain always '/'
   *
   * @readonly
   * @type {string}
   * @memberof LocaleService
   */
  public get LocaleHomeUrl(): string {
    return '/';
  }

  /**
   * The current router uri (no starting or trailing '/')
   *
   * @readonly
   * @type {string}
   * @memberof LocaleService
   */
  public get RouterUri(): string {
    return this.router.url.replace(
      new RegExp( '^' + this.LocaleHomeUrl, 'gi' ),
      ''
    ).replace( /^\//, '' ).replace( /\/$/, '' );
  }

  public CurrentRouteComponent: ILocalizedComponent;

  public static getLocaleFromSiteId( siteId: string ): ELocale {
    if ( typeof siteId !== 'string' ) {
      siteId = ( siteId as any ).toString();
    }
    switch ( siteId ) {
      case '3':
        return ELocale.en;
      case '2':
        return ELocale.de;
      case '1':
      default:
        return ELocale.ch;
    }
  }

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.setInitialLocale().subscribe(
      canActivate => {
        // console.log( 'canActivate = ' + ( canActivate ? 'true' : 'false' ) );
      }
    );
  }


  /**
   * Set locale on pageload
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean>}
   * @memberof LocaleService
   */
  public setInitialLocale(): Observable<boolean> {
    // get locale from cookie
    // const cookieLocale = this.CookiesSvc.getCookie( this.LOCALE_COOKIE_NAME ) as ELocale;
    const domainLocale = UrlHelper.currentLocaleFromDomain();
    // console.log( 'domainLocale=' + domainLocale, 'cookieLocale=' + cookieLocale );

    // if (
    //   typeof cookieLocale === 'string'
    //   && cookieLocale.length === 2
    // ) {
    //   const redirect = cookieLocale !== domainLocale;
    //   // console.log( 'setInitialLocale: cookie', redirect, this._locale, cookieLocale );
    //   this.setLocale( cookieLocale );
    //   return of( !redirect );
    // } else {
      this.setLocale( domainLocale );
      return of( true );
      // return this.getLocaleFromClientIp().pipe(
      //   map(
      //     clientLocale => {
      //       // use browser locale if clientLocale invalid
      //       console.log( domainLocale, clientLocale );
      //       const redirect = domainLocale !== clientLocale;
      //       console.log( 'setInitialLocale, getLocaleFromClientIp', 'redirect:', redirect );
      //       this.setLocale( clientLocale );
      //       return !redirect;
      //     }
      //   )
      // );
    // }
  }

  /**
   * Changes page to a new locale.
   * If the parmeter is invalid, the default locale '' will be used.
   *
   * @param {string} [locale] Allowed values: '' | 'xx' | xx_XY
   * @returns {boolean} true if locale is different from previous one
   * @memberof LocaleService
   */
  public setLocale( locale?: ELocale ): boolean {
    const redirect = true;

    this._locale = this.getValidLocaleFromStr( locale );
    const prevLocale = this._locale;

    // this.CookiesSvc.setCookie(
    //   this.LOCALE_COOKIE_NAME,
    //   this._locale,
    //   60, 'days',
    //   window.location.hostname,
    //   '/',
    //   window.location.protocol === 'https:'
    // );

    const localeChanged = prevLocale !== this._locale || this._locale !== UrlHelper.currentLocaleFromDomain();
    // console.log( 'setLocale from "' + prevLocale + '" to "' + this._locale + '"', 'localeChanged', localeChanged, 'redirect', redirect );
    if ( localeChanged && redirect ) {
      // console.log( this.CurrentRouteComponent );
      if (
        this.CurrentRouteComponent
        && typeof this.CurrentRouteComponent.getLocaleChangeUrl === 'function'
      ) {
        const newUrl = ( this.CurrentRouteComponent.getLocaleChangeUrl( this._locale ) || UrlHelper.LocaleHomeUrl( this._locale ) );
        // alert(
        //   'navigating by component uri from "' + this.router.url + '" to "' + newUrl + '"'
        //   + '\ngetLocaleChangeUrl: '
        //   + ( this.CurrentRouteComponent.getLocaleChangeUrl( this._locale ) || UrlHelper.LocaleHomeUrl( this._locale ) )
        // );
        window.location.href = newUrl;
      } else {
        const newUrl = this.getLocalizedCurrentUrl();
        // alert( 'navigating from "' + this.router.url + '" to "' + newUrl + '"' );
        window.location.href = newUrl;
      }
    }
    return localeChanged;
  }

  public getLocaleFromClientIp(): Observable<ELocale> {
    return this.http.get<ELocale>(
      API_BASE_URL + 'locale',
      {
        headers: new HttpHeaders().set( 'X-Requested-With', 'XMLHttpRequest' ),
        withCredentials: true
      }
    );
  }

  public isLocaleHomeUrl(): boolean {
    const url = this.getCurrentLocationPath();
    const urlLocaleMatches = url.match( /^\/$/i );
    // console.log( 'isLocaleHomeUrl() = ', Array.isArray( urlLocaleMatches ) && urlLocaleMatches.length > 0, url );
    return Array.isArray( urlLocaleMatches ) && urlLocaleMatches.length > 0;
  }

  public getLocalizedCurrentUrl(): string {
    let url = this.getCurrentLocationPath();
    // console.log( 'getLocalizedCurrentUrl() before', url );
    url = UrlHelper.LocaleHomeUrl( this._locale ) + url;
    // console.log( 'getLocalizedCurrentUrl() after', url );
    return url;
  }

  public navigateToHome() {
    // console.log( 'navigateToHome', this.LocalePathPre );
    this.router.navigate( [ this.LocalePathPre ] );
  }

  public getCurrentLocationPath(): string {
    const path = location.href
      .replace( /^https?:\/\/.*?\//i, '/' )
      .replace( environment.baseHref, '/' );
    // console.log( 'getCurrentLocationPath()', path, 'env homeUrl = ' + environment.domains[ this._locale ].homeUrl );
    return path;
  }

  public isCurrentPageOrParent( uri: string ): boolean {
    // remove leading and trailing /
    uri = uri.replace( /^\/|\/$/g, '' );
    const currentLocationPath = this.getCurrentLocationPath().replace( /^\/|\/$/g, '' );
    const currentLocationPathSegments = currentLocationPath.split( '/' );
    const uriSegments = uri.split( '/' );

    let matchingSemgents = 0;
    for (
      matchingSemgents = 0;
      matchingSemgents < uriSegments.length
      && matchingSemgents < currentLocationPathSegments.length
      && uriSegments[ matchingSemgents ] === currentLocationPathSegments[ matchingSemgents ];
      ++matchingSemgents
    ) { }

    // console.log( matchingSemgents, currentLocationPath, uri );
    return currentLocationPath === uri;
  }

  private getValidLocaleFromStr( locale?: string ): ELocale {
    if ( typeof locale !== 'string' ) {
      return ELocale.ch;
    }

    locale = locale.toLowerCase();
    if (
      locale.length === 0
      || locale === 'ch'
      || locale === 'li'
      || locale === 'de_ch'
      || locale === 'de_li'
    ) {
      return ELocale.ch;
    } else if (
      locale === 'de'
      || locale === 'at'
      || locale === 'de_de'
      || locale === 'de_at'
    ) {
      return ELocale.de;
    }
    return ELocale.en;
  }
}
