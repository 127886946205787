import { Component, Input } from '@angular/core';
import { LocaleService } from 'src/app/localization/locale.service';
import { IMcbJournalEintraegePreview } from 'src/rest-interfaces/IModularContent.field';

@Component( {
  selector: 'app-sidebar-journal-preview',
  templateUrl: 'sidebar-journal-preview.component.html'
} )
export class SidebarJournalPreview {
  @Input() public content: IMcbJournalEintraegePreview;

  constructor(
    public localeSvc: LocaleService
  ) { }
}
