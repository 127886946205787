import { trigger, state, animate, transition, group, query, stagger, style } from '@angular/animations';

export const generalAnimations =
    trigger('generalAnimations', [

        transition( ':enter', [
            // initial states
            query( 'app-breadcrumbs', style( { opacity: 0, transform: 'translateX(-10px)' } ), {optional: true}),
            // if no breadcrumps on page (replace)
            query( '.breadcrumbs-replace-animation', style( { opacity: 0, transform: 'translateY(-10px)' } ), {optional: true}),
            query( '.page-content-top-replace-animation', style( { opacity: 0, transform: 'translateY(-10px)' } ), {optional: true}),
            // if no page-top-content (replace)
            query( '.page-content-top', style( { opacity: 0, transform: 'translateY(-10px)' } ), {optional: true}),
            query( '.animation-2', style( { opacity: 0, transform: 'translateY(-10px)' } ), {optional: true}),
            query( '.animation-3', style( { opacity: 0, transform: 'translateY(-10px)' } ), {optional: true}),
            query( '.animation-4', style( { opacity: 0, transform: 'translateY(-10px)' } ), {optional: true}),
            // app-breadcrumbs
          group( [
            // enter states
            query( 'app-breadcrumbs', [
              animate( '500ms 500ms ease-out', style( {opacity: 1, transform: 'translateX(0)'} ) )
            ], {optional: true}),
            query( '.breadcrumbs-replace-animation', [
              animate( '500ms 500ms ease-out', style( {opacity: 1, transform: 'translateY(0)'} ) )
            ], {optional: true}),
            query( '.page-content-top', [
              animate( '500ms 1000ms ease-out', style( {opacity: 1, transform: 'translateY(0)'} ) )
            ], {optional: true}),
            query( '.page-content-top-replace-animation', [
              animate( '500ms 1000ms ease-out', style( {opacity: 1, transform: 'translateY(0)'} ) )
            ], {optional: true}),
            query( '.animation-2', [
              animate( '500ms 1500ms ease-out', style( {opacity: 1, transform: 'translateY(0)'} ) )
            ], {optional: true}),
            query( '.animation-3', [
              animate( '500ms 2000ms ease-out', style( {opacity: 1, transform: 'translateY(0)'} ) )
            ], {optional: true}),
            query( '.animation-4', [
              animate( '500ms 2500ms ease-out', style( {opacity: 1, transform: 'translateY(0)'} ) )
            ], {optional: true}),
        ])
      ]),
    ]);
