import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { IEntryJournal as IEntryJournal } from 'src/rest-interfaces/IEntryJournal';
import { LocaleService } from 'src/app/localization/locale.service';
import { SliderConfigService } from 'src/app/services/slider-config.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { JournalService } from 'src/app/services/journal.service';
import { ModularContentHelper } from 'src/app/helpers/modular-content.helper';
import { generalAnimations } from 'src/app/_animations/general.animations';
import { EContentBlockOrientation } from 'src/rest-interfaces/EContentBlockOrientation';
import { JournalComponent } from '../journal.component';

@Component( {
  selector: 'app-journal-item',
  templateUrl: './journal-item.component.html',
  animations: [ generalAnimations ]
} )
export class JournalItemComponent {
  public content: IEntryJournal;
  public authorOrientationColClasses = ModularContentHelper.AuthorOrientationToColClasses(
    EContentBlockOrientation.Rechts
  );


  constructor(
    public journalSvc: JournalService,
    private localeSvc: LocaleService,
    public SliderConfigSvc: SliderConfigService,
    private scrollService: ScrollService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.route.url.subscribe(
      () => {
        this.journalSvc.uri(
          this.localeSvc.RouterUri
        ).subscribe(
          t => {
            if ( t ) {
              t.showHdhIcon = JournalComponent.hasHdhCategory( t );
              if ( t.hasOwnProperty( 'storyElemente' ) && Array.isArray( t.storyElemente ) ) {
                JournalComponent.updateHdHIconFlags( t.storyElemente );
              }
            }

            this.content = t;
            this.title.setTitle(
              `PIRMIN JUNG - ${typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title}`
            );
            this.authorOrientationColClasses = ModularContentHelper.AuthorOrientationToColClasses( this.content.autorPosition );
          },
          () => {
            this.router.navigate( [ '..' ], { relativeTo: route } );
          }
        );
      }
    );
  }

  public onScrollDown( event: Event ) {
    this.scrollService.triggerScrollToOffset( 'scrollDestination', -100 );
  }
}
