import { Injectable } from '@angular/core';

@Injectable()
export class SliderConfigService {
  public HauptbildSlider: object = {
    appendArrows: 'ngx-slick-carousel + .slider-arrows',
    arrows: true,
    // autoplay: true,
    dots: false,
    draggable: true,
    infinite: true,
    nextArrow: '<span class="slick-next slick-arrow"></span>',
    prevArrow: '<span class="slick-prev slick-arrow"></span>',
    rows: 1
  };

  public SlideshowSlider: object = {
    arrows: false,
    autoplay: true,
    dots: false,
    draggable: true,
    infinite: true,
    rows: 1,
    autoplaySpeed: 300000,
    speed: 800
  }

  public ZeistrahlSlider: object = {
    appendArrows: 'ngx-slick-carousel + .slider-arrows',
    arrows: true,
    // autoplay: true,
    dots: false,
    draggable: true,
    infinite: false,
    nextArrow: '<span class="slick-next slick-arrow"></span>',
    prevArrow: '<span class="slick-prev slick-arrow"></span>',
    rows: 1,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
}
