import { Component, ElementRef, HostListener, OnInit, Query, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { headerAnimations } from '../_animations/header.animations';
import { LightboxService } from '../services/lightbox.service';

@Component( {
  selector: 'lightbox',
  templateUrl: './lightbox.component.html',
  animations: [ headerAnimations ]
} )
export class LightboxComponent implements OnInit {

  @ViewChildren("carouselItem")
  public carousels: QueryList<any>

  private myCarousel: any;

  public isZoomed: boolean = false;
  public zoomX: number = 50;
  public zoomY: number = 50;

  public innerWidth: any;
  public innerHeight: any;


  constructor(
    public lightboxSvc: LightboxService,
  ) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    this.lightboxSvc.customObservable.subscribe((res) => {
        this.focusLightbox()
      }
    );
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.closeLightbox();
    }
  }

  public focusLightbox() {

    this.carousels.changes.subscribe((comps: QueryList <any>) =>
    {
      this.myCarousel = comps.first;
      if(this.myCarousel) {
        this.myCarousel.nativeElement.focus();
        this.isZoomed = false;
      }
    });
  }
  
  public closeLightbox() {
    this.lightboxSvc.hideLightbox()
  }

  public afterChange( event: Event ) {
    this.lightboxSvc.currentSlide = ( event as any ).currentSlide;
  }

    public beforeChange( event: Event ) {
    this.isZoomed = false;
  }

  public toggleImageZoom() {
    this.isZoomed = !this.isZoomed;
  }

  public getCursor() {
    return this.isZoomed ? 'zoom-out' : 'zoom-in';
  }

  public getBackgroundsize() {
    return this.isZoomed ? '300% auto' : 'contain';
  }

  public getBackgroundposition() {
    return this.isZoomed ? this.zoomX + '% ' + this.zoomY + '%' : 'center';
  }

  public mouseMoved(e: any) {
    this.zoomX = e.offsetX / e.srcElement.clientWidth * 100;
    this.zoomY = e.offsetY / e.srcElement.clientHeight * 100;
  }

  public touchMoved(e: any) {
    // console.log('touchMoved', e);
    e.preventDefault();

    let zoomXCalc = e.targetTouches[0].clientX / e.target.clientWidth * 100;
    this.zoomX = Math.min(Math.max(zoomXCalc, 0), 100);


    let zoomYCalc = (e.targetTouches[0].clientY - ((this.innerHeight - e.targetTouches[0].clientY) / 2)) / e.target.clientHeight * 100;
    this.zoomY = Math.min(Math.max(zoomYCalc, 0), 100);
  }

}
