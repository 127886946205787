export class NgXCookies {

  /**
   * Gets a  cookie by it's name
   *
   * @param  {string} cookieName Name/ID of cookie
   * @returns Cookies value
   */
  public static getCookie( cookieName: string ): string {
    const cookieExists = NgXCookies.exists( cookieName );

    if ( cookieExists ) {
      cookieName = encodeURIComponent( cookieName );

      const regexp = new RegExp( '(?:^' + cookieName + '|;\\s*' + cookieName + ')=(.*?)(?:;|$)', 'g' );
      const cookies = regexp.exec( document.cookie );

      return decodeURIComponent( cookies[ 1 ] );
    } else {
      return '';
    }
  }

  /**
   * Sets the Cookie by name
   *
   * @param  {string} cookieName Name/ID of cookie
   * @param  {string} value cookie value
   * @param  {number} validity expiration date of cookie (default is minutes).
   * @param  {string} validityType Unit for specifying validity time: days || hours . If left blank, default validity is in minutes
   * @param  {string} domain Set a specific domain for the cookie to be reachable at
   * @param  {string} path Path relative to domain
   * @param  {boolean} needsSecureConnection true/false if cookie can only be accessed through secure
   */
  public static setCookie(
    cookieName: string,
    value: string,
    validity?: number,
    validityType?: 'days' | 'hours' | 'minutes',
    domain?: string,
    path?: string,
    needsSecureConnection?: boolean
  ) {
    let cookieStr = encodeURIComponent( cookieName ) + '=' + encodeURIComponent( value ) + ';';

    if ( typeof path === 'string' && path.length > 0 ) {
      cookieStr += 'path=' + path + ';';
    }

    if ( typeof domain === 'string' && domain.length > 0 && domain !== 'localhost' ) {
      let dotsCount = 0;
      for ( let i = 0; i < domain.length; ++i ) { if ( domain.charAt( i ) === '.' ) { ++dotsCount; } }
      if ( dotsCount === 1 ) {
        domain = '.' + domain;
      }
      cookieStr += 'domain=' + domain + ';';
    }

    /**
     * Sets validity of cookie
     */
    if ( validity ) {

      let fullValidity = validity * 1000 * 60;

      if ( validityType === 'days' ) {
        fullValidity *= ( 60 * 24 );
      } else if ( validityType === 'hours' ) {
        fullValidity *= 60;
      }

      const daysValid = new Date( new Date().getTime() + fullValidity );

      cookieStr += 'expires=' + daysValid.toUTCString() + ';';
    }

    if ( needsSecureConnection ) {
      cookieStr += 'secure;';
    }

    document.cookie = cookieStr;
  }

  /**
   * Deletes a specific cookie
   *
   * @param  {string} cookieName Name/ID of cookie
   * @param  {string} domain Set a specific domain for the cookie to be reachable at
   * @param  {string} path Path relative to domain
   */
  public static deleteCookie( cookieName: string, domain?: string, path?: string ) {
    const cookieExists = NgXCookies.exists( cookieName );

    // If the cookie exists
    if ( cookieExists ) {
      NgXCookies.setCookie( cookieName, '', -1, 'minutes', domain, path );
    }
  }

  /**
   * Checks if the cookie exists
   * @param  {string} cookieName Name/ID of cookie
   * @returns existence of the cookie
   */
  public static exists( cookieName: string ): boolean {
    cookieName = encodeURIComponent( cookieName );

    const regexp = new RegExp( '(?:^' + cookieName + '|;\\s*' + cookieName + ')=(.*?)(?:;|$)', 'g' );
    const exists = regexp.test( document.cookie );

    return exists;
  }
}
