import { Component, Input } from '@angular/core';
import { EColor } from 'src/rest-interfaces/IColor';
import { IMcbAngebotePreview } from 'src/rest-interfaces/IModularContent.field';
import { LocaleService } from 'src/app/localization/locale.service';
import { ELocale } from 'src/app/localization/ELocale';

@Component( {
  selector: 'app-offers-preview',
  templateUrl: './offers-preview.component.html'
} )
export class OffersPreviewComponent {
  @Input( 'content' ) content: IMcbAngebotePreview;
  public EColor = EColor;
  public offersPageLink = '';

  constructor(
    public localeSvc: LocaleService
  ) {
    this.offersPageLink = this.localeSvc.LocalePathPre
      + ( this.localeSvc.Locale === ELocale.en ? '/offers' : '/angebote' );
  }
}
