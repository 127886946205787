import { Component, Input, OnInit } from '@angular/core';
import { generalAnimations } from '../../../_animations/general.animations';
import { Meta, Title } from '@angular/platform-browser';
import { ScrollService } from '../../../services/scroll.service';
import { MedienService } from '../../../services/medien.service';
import { IJournalCategory } from 'src/rest-interfaces/journal/IJournalCategory';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '../../../localization/locale.service';
import { EntryService } from '../../../services/entry.service';
import { IEntryMedien } from 'src/rest-interfaces/IEntryMedien';

@Component( {
  selector: 'app-projekt-medien',
  templateUrl: './projekt-medien.component.html',
  animations: [ generalAnimations ]
} )

export class ProjektMedienComponent implements OnInit  {

  @Input() projectid: string;

  public stories: Array<IEntryMedien>;
  public categories: Array<IJournalCategory> = [];
  public hasMoreStories = false;
  public isLoadingMore = false;

  private currentStoriesPage = 0;
  public searchterm = '';
  public term: string;

  constructor(
    private entrySvc: EntryService,
    public medienSvc: MedienService,
    private localeSvc: LocaleService,
    private scrollService: ScrollService,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.route.url.subscribe(
      () => {
        this.entrySvc.uri(
          this.localeSvc.RouterUri
        ).subscribe(
          t => {
            this.title.setTitle( 'PIRMIN JUNG - ' + ( typeof t.metaTitle === 'string' && t.metaTitle.length > 0 ? t.metaTitle : t.title ) );
          },
        );
      }
    );
    this.medienSvc.categories().subscribe(
      categories => { this.categories = categories; }
    );
    
  }

  ngOnInit() {
    this.getStories( '' );
  }

  public getStories(term?: string ): void {

    console.log('Selected Project', this.projectid);

    if (
      typeof term === 'string'
      && term !== this.term
    ) {
      // remove all previously loaded stories
      // because we are getting new ones by category or term
      this.stories = [];
      this.currentStoriesPage = 0;
      this.hasMoreStories = false;

      if (
        typeof term === 'string'
        && term !== this.term
      ) {
        this.term = term;
      }

      // console.log( 'loading stories', this.selectedCategory, 'term="' + this.term + '"' );
      this.loadMoreStories();
    }
  }

  public loadMoreStories(): void {
    if ( this.isLoadingMore ) { return; }

    ++this.currentStoriesPage;

    this.isLoadingMore = true;
    this.medienSvc.projektFeed(
      this.currentStoriesPage,
      this.projectid,
      this.term
    ).subscribe(
      entries => {
        this.hasMoreStories = this.currentStoriesPage === 1
          && entries.length > 4
          ?
          true
          :
          entries.length > 4;
          
        this.stories = this.stories.concat(
          entries.slice( 0, 4 )
        );

        this.isLoadingMore = false;
      },
      () => {
        this.isLoadingMore = false;
      }
    );
  }

  public onScrollDown( event: Event ) {
    this.scrollService.triggerScrollToOffset( 'scrollDestination', -100 );
  }

}
