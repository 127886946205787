import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IMcbjTextBlock } from 'src/rest-interfaces/journal/IModularContentJournal.field';
import { ModularContentHelper } from 'src/app/helpers/modular-content.helper';

@Component( {
  selector: 'app-journal-text-block',
  templateUrl: './journal-text-block.component.html'
} )
export class JournalTextBlockComponent implements OnChanges {
  @Input( 'content' ) content: IMcbjTextBlock;
  public blockOrientationColClasses = ModularContentHelper.COL_CLASSES_LEFT;

  ngOnChanges( changes: SimpleChanges ) {
    for ( const key in changes ) {
      if ( !changes.hasOwnProperty( key ) ) { continue; }
      switch ( key ) {
        case 'content':
          if ( changes.content.currentValue ) {
            const content = changes.content.currentValue as IMcbjTextBlock;
            this.blockOrientationColClasses = ModularContentHelper.BlockOrientationToColClasses( content.textposition );
            this.content = content;
          } else {
            this.blockOrientationColClasses = ModularContentHelper.COL_CLASSES_LEFT;
          }
      }
    }
  }
}
